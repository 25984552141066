import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import BulkAddingResultTableViewModel from '../Companies/Viewmodels/BulkUserAddingResultTableRowViewModel';

interface IProps {
    row: BulkAddingResultTableViewModel;
}

export default function EventTableRow({ row }: IProps) {
    return (
        <TableRow>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.reason}</TableCell>
        </TableRow>
    );
}