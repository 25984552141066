import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MemberShiftManagement from './MemberShiftManagement';
import StaffDetails from './StaffDetails';
import { useLocation } from 'react-router-dom';
import ManageEntitlement from './ManageEntitlement';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import ToilAllowance from './ToilAllowance';
import ToilViewModel from './Viewmodels/ToilViewModel';

interface ILocationProps {
    userId: string;
}

export default function ManageStaffMember() {
    const [name, setName] = React.useState('');
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [toilAllowance, setToilAllowance] = React.useState<ToilViewModel[]>([]);
    const [loadingToil, setLoadingToil] = React.useState(true);

    const location = useLocation();
    const { userId } = location.state as ILocationProps || {
        userId: ""
    }

    React.useEffect(() => {
        getData();
        getToil();
    }, [userId]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Staff/GetName?id=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: string) => {
                setName(data);
            }
        );
    }

    const getToil = async () => {
        const token = await authService.getAccessToken();
        crabFetch(`Staff/GetToilAllowance?userId=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setToilAllowance(data);
                setLoadingToil(false);
            },
            (error: any) => {
                setLoadingToil(false);
            }
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid container spacing={2} item xs={12}>
                <Grid item>
                    <Typography variant="h1">Staff Overview - {name}</Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" spacing={2} direction={{ xs: 'row-reverse', md: 'row' }} item xs={12}>
                <Grid container item xs={12} md={8} spacing={2} >
                    <Grid item xs={12}>
                        <MemberShiftManagement userId={userId} />
                    </Grid>
                    <ManageEntitlement userId={userId} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Grid sx={{ pb: 2 }}>
                        <StaffDetails userId={userId} />
                    </Grid>
                    <Grid>
                        <ToilAllowance toilAllowance={toilAllowance} loading={loadingToil} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}