import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import { DatePickerElement, FormContainer, TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/en-gb';
import TextField from '@mui/material/TextField';
import EntitlementChangeViewModel from './Viewmodels/EntitlementChangeViewModel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    type: string;
    userId: string;
}

export default function AddEntitlementChange(props: IProps) {
    const { open, onClose, type, userId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [form, setForm] = React.useState<EntitlementChangeViewModel>(new EntitlementChangeViewModel());
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (type === 'Change') {
            getData();
        }
    }, [open]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Staff/GetBaseEntitlement?userId=${userId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, }
        }, ResponseType.Text,
            (data: any) => {
                setLoading(false);
                setForm({
                    ...form,
                    change: data
                })
            }
        );
    }

    const submit = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        if (form.goesIntoEffectOn) {
            const offset = form.goesIntoEffectOn.utcOffset();
            form.goesIntoEffectOn = form.goesIntoEffectOn.add(offset, 'm');
        }

        crabFetch(`Staff/AddEntitlementChange?type=${type}&userId=${userId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${type === 'Change' ? 'changed' : 'adjusted'} entitlement`);
                    closeDialog(true);
                    setLoading(false);
                }
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const closeDialog = (refresh: boolean) => {
        setForm(new EntitlementChangeViewModel());
        onClose(refresh);
    }

     return (
        <Dialog
            open={open}
            onClose={() => closeDialog(false)}
            fullWidth
        >
            <FormContainer onSuccess={submit}>
                <DialogTitle>{type === 'Change' ? 'Change FTE Entitlement' : 'Adjust FTE Entitlement'}</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                            <Grid container spacing={2}>
                                {!loading &&
                                    <Grid item xs={12}>
                                        <InputLabel htmlFor="change" shrink>{type === 'Change' ? "New FTE Entitlement in Days" : "Entitlement Adjustment in Days"}</InputLabel>
                                        <TextField autoComplete='off' name="change" value={form.change}  disabled={loading} required type={'number'} fullWidth size="small" />
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <InputLabel htmlFor="goesIntoEffectOn" shrink>{type === 'Change' ? "Date New Entitlement Starts" : "Date Adjustment Added"}</InputLabel>
                                    <DatePickerElement name="goesIntoEffectOn"  disabled={loading} required inputProps={{ fullWidth: true, size: "small" }} textReadOnly />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="reason" shrink>Reason</InputLabel>
                                    <TextFieldElement autoComplete='off' name="reason" disabled={loading} required fullWidth size="small" multiline validation={{ validate: value => value.trim() !== "" || "Field cannot be empty" }} />
                                </Grid>
                            </Grid>
                    </LocalizationProvider>
                    <DialogActions>
                        <LoadingButton loading={loading} type="submit" variant="contained" fullWidth>{type === 'Change' ? 'Save Change' : 'Save Adjustment'}</LoadingButton>
                        <Button disabled={loading} variant="outlined" onClick={() => closeDialog(false)} color="error" fullWidth>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}