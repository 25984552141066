import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import EntitlementViewModel from './Viewmodels/EntitlementViewModel';
import EntitlementTableRow from './EntitlementTableRow';
import AddEntitlementChange from './AddEntitlementChange';
import Button from '@mui/material/Button';

interface IProps {
    userId: string;
    type: string;
    refreshSummary: () => void;
}

export default function EntitlementTable(props: IProps) {
    const { type, userId, refreshSummary } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof EntitlementViewModel>('goesIntoEffectOn');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [results, setResults] = React.useState<EntitlementViewModel[]>([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const headCells: HeadCell<EntitlementViewModel>[] = [
        { id: 'change', property: 'Change', align: "left", disablePadding: false, label: type === 'Change' ? 'FTE Entitlement (in Days)' : 'Adjustment (in Days)' },
        { id: 'changedBy', property: 'ChangedBy', align: "left", disablePadding: false, label: 'Actioned By' },
        { id: 'goesIntoEffectOn', property: 'GoesIntoEffectOn', align: "left", disablePadding: false, label: type === 'Change' ? 'Goes Into Effect On' : 'Adjusted On' },
        { id: 'reason', property: 'Reason', align: "left", disablePadding: false, label: 'Reason' }
    ];

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Staff/GetEntitlementHistory?userId=${userId}&type=${type}&orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
                refreshSummary();
            },
            () => {
                setLoading(false);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof EntitlementViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeDialog = (refresh: boolean) => {
        setDialogOpen(false);

        if (refresh) {
            getData();
        }
    }

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <Grid container spacing={2} justifyContent="space-between" >
            <Grid item>
                <Typography variant="h2">FTE Entitlement {type === 'Change' ? 'History' : 'Adjustments'}</Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
                <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>{type === 'Change' ? 'Change' : 'Adjust'} FTE Entitlement</Button>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <ResponsiveTable
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="entitlement table"
                    >
                        <SortableTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                        />
                        <TableBody>
                            {(!loading && results) && results.map((row: EntitlementViewModel) => {
                                return (<EntitlementTableRow key={row.id} row={row} />);
                            })
                            }
                            {count <= 0 && !loading &&
                                <TableRow
                                    sx={{
                                        height: 53 * rowsPerPage,
                                    }}
                                >
                                    <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                </TableRow>
                            }
                            {count > 0 && emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={headCells.length + 1} />
                                </TableRow>
                            )}
                            {loading &&
                                <TableRow
                                    sx={{
                                        height: 53 * rowsPerPage,
                                    }}
                                >
                                    <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </ResponsiveTable>
                </TableContainer>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <AddEntitlementChange userId={userId} type={type} open={dialogOpen} onClose={closeDialog}/>
        </Grid>
    );
}