import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import TableHead from '@mui/material/TableHead';
import ResultTableRow from './ResultTableRow';
import BulkAddingResultTableViewModel from '../Companies/Viewmodels/BulkUserAddingResultTableRowViewModel';
import Button from '@mui/material/Button';

interface IProps {
    results: BulkAddingResultTableViewModel[];
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function UploadResultsTable({ results, open, onClose }: IProps) {

    return (
        <Dialog
            open={open}
            fullWidth>
            <Paper>
                <DialogTitle>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>Upload Results</Grid>
                    </Grid>
                </DialogTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <ResponsiveTable
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="holiday table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>User Email</TableCell>
                                        <TableCell>Reason</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results && results.map((row: BulkAddingResultTableViewModel) => {
                                        return (<ResultTableRow row={row} />);
                                    })
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Button variant="contained" onClick={() => onClose(false)} fullWidth>Done</Button>
            </Paper>
        </Dialog>
    );
}