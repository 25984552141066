import * as React from 'react';
import Grid from '@mui/material/Grid';
import authService from '../../../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../../../Contexts/TokenRefreshContext';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import RequestStatsViewModel from '../Viewmodels/RequestStatsViewModel';
import { darken } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function RequestChart() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [loading, setLoading] = React.useState(false);

    const [typeChartData, setTypeChartData] = React.useState<{
        labels: string[];
        datasets: { label: string; data: number[]; backgroundColor: string[]; borderColor: string[]; borderWidth: number }[];
    }>({
        labels: [],
        datasets: [{
            label: 'Number of Requests',
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
        }]
    });

    const [statusChartData, setStatusChartData] = React.useState<{
        labels: string[];
        datasets: { label: string; data: number[]; backgroundColor: string[]; borderColor: string[]; borderWidth: number }[];
    }>({
        labels: [],
        datasets: [{
            label: 'Number of Requests by Status',
            data: [],
            backgroundColor: ['rgba(153, 102, 255, 0.2)'],
            borderColor: ['rgba(153, 102, 255, 1)'],
            borderWidth: 1,
        }]
    });

    React.useEffect(() => {
        getTypes();
    }, []);

    const getTypes = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(
            `Event/GetRequestTypeCount`,
            {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            },
            ResponseType.JSON,
            (data: RequestStatsViewModel[]) => {
                setLoading(false);

                const backgroundColors = ['#FF7A04', '#3545B4', '#A974D3', '#EF3DA1', '#34C3CC', '#34CDDD', '#CC3461', '#B80000', '#ffc0cb', '#800080'];
                const darkenedBorderColors = backgroundColors.slice(0, data.length).map(color => darken(color, 0.25));

                setTypeChartData({
                    labels: data.map(x => x.name),
                    datasets: [{
                        label: 'Requests Types',
                        data: data.map(x => x.count),
                        backgroundColor: backgroundColors.slice(0, data.length),
                        borderColor: darkenedBorderColors,
                        borderWidth: 1,
                    }]
                })
            },
            () => {
                setLoading(false);
            }
        );

        await crabFetch(
            `Event/GetRequestStatusCount`,
            {
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            },
            ResponseType.JSON,
            (data: RequestStatsViewModel[]) => {
                const backgroundColors = ['#FF7A04', '#3545B4', '#A974D3', '#EF3DA1', '#34C3CC', '#34CDDD', '#CC3461', '#B80000', '#ffc0cb', '#800080'];
                const darkenedBorderColors = backgroundColors.slice(0, data.length).map(color => darken(color, 0.25));

                setStatusChartData({
                    labels: data.map(x => x.name),
                    datasets: [{
                        label: 'Status',
                        data: data.map(x => x.count),
                        backgroundColor: backgroundColors.slice(0, data.length),
                        borderColor: darkenedBorderColors,
                        borderWidth: 1,
                    }]
                })
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    };

    const options = {
        responsive: false,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            }
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    return (
        <>
            {!loading &&
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography>Requests by Type</Typography>
                        <Bar data={typeChartData} options={options} />
                    </Grid>
                    <Grid item>
                        <Typography>Requests by Status</Typography>
                        <Bar data={statusChartData} options={options} />
                    </Grid>
                </Grid>
            }
        </>
    );
}
