import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ShiftWeekViewModel from './Viewmodels/ShiftWeekViewModel';
import Typography from '@mui/material/Typography';
import { WeekDayOptions } from '../Utilities/CalendarEnums';
import { TimeField } from '@mui/x-date-pickers/TimeField';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';


interface IProps {
    week: ShiftWeekViewModel;
    remove?: (week: number) => void;
    update?: (week: ShiftWeekViewModel) => void;
    workingWeekStart: number;
}

export default function ShiftPatternWeek(props: IProps) {
    const { week, remove, update, workingWeekStart } = props;

    const onTimeChange = (e: any, index: number, time: string) => {
        const updatedWeek = { ...week };
        updatedWeek.days[index][time] = dayjs(e);

        if (update)
            update(updatedWeek);
    }

    return (
        <Grid container spacing={1} alignItems="center" justifyContent={{ md: 'space-between' }}>
            <Grid item xs={12}>
                <Typography variant="h2">Week {week.weekIndex}{week.currentWeek && ' - Current week'}</Typography>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
                    // This calculates the correct starting day of the week for 
                    // this user and displayed the days of the week accordingly 
                    const adjustedIndex = (dayIndex + workingWeekStart) % 7;
                    const day = week.days.find((d) => d.dayIndex === adjustedIndex);
                    return (
                        <Grid item key={'day' + adjustedIndex + 'week' + week.weekIndex} xs={1.5}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography>{WeekDayOptions[adjustedIndex]}</Typography>
                                </Grid >
                                <Grid item container xs={12} spacing={0.5}>
                                    <Grid item xs={12} lg={6}>
                                        <TimeField
                                            name="startTime"
                                            inputProps={{ spellCheck: 'false' }}
                                            value={day?.startTime}
                                            onChange={(e) => onTimeChange(e, adjustedIndex, "startTime")}
                                            format="HH:mm"
                                            disabled={!update}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <TimeField
                                            name="endTime"
                                            inputProps={{ spellCheck: 'false' }}
                                            value={day?.endTime}
                                            onChange={(e) => onTimeChange(e, adjustedIndex, "endTime")}
                                            format="HH:mm"
                                            disabled={!update}
                                            size="small"
                                        />
                                    </Grid>
                                </Grid >
                            </Grid>
                        </Grid>
                    );
                }
                )}
            </LocalizationProvider>
            {remove &&
                <Grid item xs={1.5} sx={{ mt: 2.5 }}>
                    <Button variant="contained" fullWidth color="error" onClick={() => remove(week.weekIndex)}>Remove Week</Button>
                </Grid>
            }
        </Grid>
    );
}