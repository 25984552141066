import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import { FormContainer, SelectElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import 'moment/locale/en-gb';
import FileUpload from '../Utilities/FileUpload';
import BulkAddStaffViewModel from './Viewmodels/BulkAddStaffViewModel';
import { useForm } from 'react-hook-form'
import ModifyShiftPatternDialog from '../Shifts/ModifyShiftPatternDialog';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import InputLabel from '@mui/material/InputLabel';
import BulkAddingResultTableViewModel from '../Companies/Viewmodels/BulkUserAddingResultTableRowViewModel';
import UploadResultsTable from './UploadResultsTable';
import LoadingButton from '@mui/lab/LoadingButton';
import SendRounded from '@mui/icons-material/SendRounded';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function BulkUploadStaff({ open, onClose }: IProps) {

    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [files, setFiles] = React.useState<File | null>(null);
    const [openShiftDialog, setOpenShiftDialog] = React.useState(false);
    const [openResultDialog, setOpenResultDialog] = React.useState(false);
    const [shiftPatterns, setShiftPatterns] = React.useState<DropdownViewModel[]>([]);
    const [results, setResults] = React.useState<BulkAddingResultTableViewModel[]>([]);
    const [loading, setLoading] = React.useState(false);

    const formContext = useForm<BulkAddStaffViewModel>({
        defaultValues: {
            staff: null,
            shiftPattern: 0
        }
    });

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch('Shift/GetShiftPatternDropdown', {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setShiftPatterns(data);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        if (files)
            form.staff = files;

        let formData = new FormData();
        formData.append("staff", form.staff);
        formData.append("shiftPattern", form.shiftPattern);

        crabFetch('Staff/BulkAddStaff', {
            method: 'POST',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            body: formData
        }, ResponseType.Response,
            (response: any) => {
                setLoading(false);
                if (response.ok) {
                    response.json()
                        .then((data: BulkAddingResultTableViewModel[]) => {
                            if (data.length > 0) {
                                setResults(data);
                                setOpenResultDialog(true);
                                closeDialog(false);
                            } else {
                                show('success', "Successfully created new staff members.");
                                closeDialog(true);
                            }
                        });
                }
                response.text()
                    .then((data: string) => {
                        show('error', data);
                    });
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }

        );
    }

    const download = async () => {
        setLoading(true);
        const downloadLink = document.createElement('a');
        downloadLink.href = 'bulkUploadTemplate.csv';
        downloadLink.download = 'bulkUploadTemplate.csv';
        downloadLink.click();
        setLoading(false);
    }

    const onFileDrop = (newFiles: File | null) => {
        setFiles(newFiles);
    }

    const onFileDelete = (index: number) => {
        setFiles(null);
    }

    const closeDialog = (refresh: boolean) => {
        setFiles(null);
        onClose(refresh);
    }

    const closeShiftDialog = (refresh: boolean) => {
        setOpenShiftDialog(false);
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => closeDialog(false)}
                fullWidth
            >
                <FormContainer
                    onSuccess={submit}
                    formContext={formContext}
                >
                    <DialogTitle>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>Bulk Upload Staff</Grid>
                            <Button variant="contained" onClick={download}>Download Template</Button>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <Grid>
                            <Grid>
                                <FileUpload onDelete={onFileDelete} onDrop={onFileDrop} files={files} fileTypes='.csv' />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} pt={3}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="shiftPattern" shrink>Shift Pattern</InputLabel>
                                <SelectElement name="shiftPattern" required options={shiftPatterns} fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="outlined" onClick={() => setOpenShiftDialog(true)}>Create Shift Pattern</Button>
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <LoadingButton variant="contained" type="submit" disabled={loading} fullWidth endIcon={<SendRounded />}>Invite</LoadingButton>
                            <Button variant="outlined" onClick={() => closeDialog(false)} disabled={loading} color="error" fullWidth>Cancel</Button>
                        </DialogActions>
                    </DialogContent>
                </FormContainer>
            </Dialog>
            <ModifyShiftPatternDialog open={openShiftDialog} onClose={closeShiftDialog} />
            <UploadResultsTable open={openResultDialog} results={results} onClose={setOpenResultDialog} />
        </>
    );
}