import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../../Contexts/TokenRefreshContext';
import { AlertContext } from '../../Contexts/AlertContext';
import authService from '../../api-authorization/AuthorizeService';
import { DatePickerElement, FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import UserEventViewModel from '../Viewmodels/UserEventViewModel';
import EventTypeDropdownViewModel from '../../Event/Viewmodels/EventTypeDropdownViewModel';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import LoadingButton from '@mui/lab/LoadingButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import { partialDayOptions } from '../../Utilities/CalendarEnums';
import CloseDialogConfirmation from '../../Utilities/CloseDialogConfimation';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    eventId: number;
    userStartDate: Date;
    userLeaveDate?: Date | null;
}

export default function ModifyUserEvent(props: IProps) {
    const { open, onClose, eventId, userStartDate, userLeaveDate } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [eventTypes, setEventTypes] = React.useState<EventTypeDropdownViewModel[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [saving, setSaving] = React.useState(false);
    const [openConfimation, setOpenConfirmation] = React.useState(false);
    const [disableForm, setDisableForm] = React.useState(true);
    const [startTime, setStartTime] = React.useState<Dayjs | null>(null);
    const [endTime, setEndTime] = React.useState<Dayjs | null>(null);
    const formContext = useForm({
        defaultValues: new UserEventViewModel()
    });
    const { reset, watch, setValue } = formContext;
    const watchStartDate = watch('start', dayjs());
    const watchEndDate = watch('end', dayjs());
    const watchUser = watch('userId', undefined);
    const watchEventType = watch('eventType', 0);

    React.useEffect(() => {
        getData();
    }, [watchUser]);

    React.useEffect(() => {
        getEvent();
    }, [eventId]);

    React.useEffect(() => {
        const newEvent = new UserEventViewModel();
        newEvent.id = eventId;

        reset(newEvent);
    }, [eventId]);

    React.useEffect(() => {
        if (dayjs.isDayjs(watchEndDate) && watchEndDate.set('hour', 1).diff(watchStartDate.set('hour', 1), 'hours') < 12) {
            if (startTime && endTime && endTime < startTime) {
                setEndTime(startTime.add(1, 'h'));
            }
        }
    }, [startTime, endTime]);

    React.useEffect(() => {
        if (eventTypes.length > 0 && watchEventType && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)?.requiresTime) {
            setValue("end", watchStartDate);
            setValue("startDayType", 0);
            setValue("endDayType", 0);
        } else if (watchStartDate.isSame(watchEndDate)) {
            setValue("endDayType", 0);
        }
    }, [watchEventType, watchStartDate, watchEndDate]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Event/GetEventTypeDropdown?id=${watchUser ?? ""}&includeArchived=true`,{
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setEventTypes(data);
            }
        );
    }

    const getEvent = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        crabFetch('Event/GetStaffLeave?id=' + eventId, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                const startUtc = new Date(data.start)
                data.start = dayjs(startUtc);
                data.end = dayjs(new Date(data.end));
                data.fullStartDay = data.partialStartDay === 0;
                data.fullEndDay = data.partialEndDay === 0;
                setStartTime(data.start);
                setEndTime(data.end);
                reset(data);
                setLoading(false);
            }
        );

        crabFetch(`Event/GetEventEditability?eventId=${eventId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: boolean) => {
                setDisableForm(!data);
            }
        );
    }

    const submit = async (form: any) => {
        setSaving(true);
        const token = await authService.getAccessToken();

        if (form.start) {
            const start = new Date(form.start);
            start.setHours(12);

            if (eventTypes.find(f => f.id === watchEventType)!.requiresTime) {
                if (startTime === null || !startTime.isValid()) {
                    show('error', "Invalid start time")
                    setSaving(false);
                    return;
                }
                start.setHours(startTime!.hour());
                start.setMinutes(startTime!.minute());
            }

            form.start = start;
        }

        if (form.end) {
            const end = new Date(form.end);
            end.setHours(12);

            if (eventTypes.find(f => f.id === watchEventType)!.requiresTime) {
                if (endTime === null || !endTime.isValid()) {
                    show('error', "Invalid end time")
                    setSaving(false);
                    return;
                }
                end.setHours(endTime!.hour());
                end.setMinutes(endTime!.minute());
            }

            form.end = end;
        }

        // Prevent submitting if positive or toil event and no times entered
        if (eventTypes.length > 0 && watchEventType && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)?.requiresTime) {
            if (!endTime?.isValid() || !startTime?.isValid()) {
                setSaving(false);
                show('error', "Start and end times are required for this type of event.");
                return;
            }

            form.startDayType = startTime.hour() < 12 ? 1 : 2;
            form.endDayType = startTime.hour() < 12 ? 1 : 2;
        }


        if (eventTypes.find(f => f.id === watchEventType)?.archived) {
            setSaving(false);
            show('error', "Cannot modify a request with an archived event type");
            return;
        }

        crabFetch('Event/ModifyUserEvent', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully modified event");
                    closeDialog(true);
                }
                setSaving(false);
            },
            (error: any) => {
                show('error', error);
                setSaving(false);
            }
        );
    }

    const remove = async () => {
        setSaving(true);
        const token = await authService.getAccessToken();

        crabFetch(`Event/RemoveUserEvent?userId=${watchUser}&eventId=${eventId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully removed event");
                    closeDialog(true);
                }
                setSaving(false);
            },
            (error: any) => {
                show('error', error);
                setSaving(false);
            }
        );
    }

    const closeDialog = (refresh: boolean) => {
        if (loading) {
            return;
        }

        setStartTime(null);
        setEndTime(null);
        reset(new UserEventViewModel());
        onClose(refresh);
    }

    const closeConfimation = (refresh: boolean) => {
        setOpenConfirmation(false);
        if (refresh) {
            remove();
            closeDialog(true);
        }
    }

    const startTypeOptions = React.useMemo(() => {

        if (dayjs.isDayjs(watchEndDate) && watchStartDate.isSame(watchEndDate, 'day'))
            return partialDayOptions;
        else
            return partialDayOptions.filter(f => f.id !== 1);
    }, [watchStartDate, watchEndDate]);

    const endTypeOptions = React.useMemo(() => {
        if (dayjs.isDayjs(watchEndDate) && watchStartDate.isSame(watchEndDate))
            return partialDayOptions;
        else
            return partialDayOptions.filter(f => f.id !== 2)
    }, [watchStartDate, watchEndDate]);

    return (
        <Dialog
            open={open}
            onClose={() => closeDialog(false)}
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>Modify Event</Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                        <Grid container spacing={2} alignItems="flex-end">
                            <Grid item xs={12}>
                                <InputLabel htmlFor="eventType" shrink>Request Type</InputLabel>
                                <SelectElement disabled={disableForm || (eventTypes.find(f => f.id === watchEventType)?.archived)} name="eventType" required labelKey="name" options={eventTypes} fullWidth size="small" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="start" shrink>First Day Of Event</InputLabel>
                                <DatePickerElement minDate={dayjs(userStartDate)} maxDate={dayjs(userLeaveDate)} disabled={disableForm || (eventTypes.find(f => f.id === watchEventType)?.archived)} onChange={(e) => { if (watchEndDate.isBefore(e, "d")) setValue("end", e); }} name="start" required inputProps={{ fullWidth: true, size: "small" }} textReadOnly />
                            </Grid>
                            {((eventTypes.length > 0 && watchEventType && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)?.requiresTime)) ?
                                <Grid item xs={12} md={6}>
                                    <TimeField disabled={disableForm || (eventTypes.find(f => f.id === watchEventType)?.archived)} fullWidth size="small" format="HH:mm" value={startTime} onChange={(newValue: Dayjs | null) => setStartTime(newValue)} />
                                </Grid>
                                :
                                <Grid item xs={12} md={6}>
                                    <SelectElement name="startDayType" required fullWidth size="small" options={startTypeOptions} disabled={(eventTypes.find(f => f.id === watchEventType)?.archived)} />
                                </Grid>
                            }
                            <Grid item xs={12} md={6}>
                                <InputLabel htmlFor="start" shrink>Last Day Of Event</InputLabel>
                                <DatePickerElement minDate={watchStartDate} maxDate={dayjs(userLeaveDate)} name="end" required inputProps={{ fullWidth: true, size: "small" }} disabled={disableForm || (eventTypes.length > 0 && watchEventType != null && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)?.requiresTime) || (eventTypes.find(f => f.id === watchEventType)?.archived)} textReadOnly />
                            </Grid>
                            {(eventTypes.length > 0 && watchEventType && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)?.requiresTime) ?
                                <Grid item xs={12} md={6}>
                                    <TimeField disabled={disableForm || (eventTypes.find(f => f.id === watchEventType)?.archived)} required={eventTypes.length > 0 && watchEventType != null && watchEventType > 0 && eventTypes.find(f => f.id === watchEventType)?.requiresTime} fullWidth size="small" format="HH:mm" value={endTime} onChange={(newValue: Dayjs | null) => setEndTime(newValue)} />
                                </Grid>
                                : (dayjs.isDayjs(watchEndDate) && watchEndDate.set('hour', 1).diff(watchStartDate.set('hour', 1), 'hours') > 12) ?
                                    <Grid item xs={12} md={6}>
                                        <SelectElement name="endDayType" required fullWidth size="small" options={endTypeOptions} disabled={(eventTypes.find(f => f.id === watchEventType)?.archived) } />
                                    </Grid>
                                    :
                                    <React.Fragment />
                            }
                            <Grid item xs={12}>
                                <InputLabel htmlFor="Note" shrink>Note</InputLabel>
                                <TextFieldElement autoComplete='off' disabled={disableForm || (eventTypes.find(f => f.id === watchEventType)?.archived)} name="note" fullWidth size="small" multiline minRows={4} />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                    <DialogActions>
                        <LoadingButton loading={saving} variant="contained" type="submit" fullWidth disabled={loading || disableForm || (eventTypes.find(f => f.id === watchEventType)?.archived)}>Save</LoadingButton>
                        <LoadingButton loading={saving} variant="contained" onClick={() => setOpenConfirmation(true)} color="error" fullWidth disabled={loading || disableForm}>Remove Request</LoadingButton>
                        <Button variant="outlined" onClick={() => closeDialog(false)} color="error" fullWidth disabled={loading || saving}>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
            <CloseDialogConfirmation title="Are you sure you want to remove this event?" confirmClosureMessage="Yes" preventClosureMessage="No" open={openConfimation} onClose={closeConfimation} />
        </Dialog>
    );
}