import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import TeamListViewModel from './Viewmodels/TeamListViewModel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TeamTableRow from './TeamTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import CreateTeam from './CreateTeam';
import WarningDialog from '../Utilities/WarningDialog';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash/debounce';
import EditTeam from './EditTeam';

const headCells: HeadCell<TeamListViewModel>[] = [
    { id: 'name', property: 'Name', align: "left", disablePadding: false, label: 'Team Name' },
    { id: 'manager', property: 'Manager', align: "left", disablePadding: false, label: 'Team Manager' },
    { id: 'members', property: 'Members', align: "right", disablePadding: false, label: 'Members' },
];

export default function TeamTable() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof TeamListViewModel>('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [results, setResults] = React.useState<TeamListViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [createOpen, setCreateOpen] = React.useState(false);
    const [editId, setEditId] = React.useState<number | null>(null);
    const [archiveTeamId, setArchiveTeamId] = React.useState<number | null>(null);

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy, search]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Team/GetTeamsTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                console.table(data);
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const archiveTeam = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Team/ArchiveTeam?teamId=${archiveTeamId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully archived team`);
                    setArchiveTeamId(null);
                    getData();
                }
            },
            (error: any) => {
                show('error', error);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof TeamListViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const debouncedhandleSearch =
        React.useCallback!(
            debounce(handleSearch, 600),
            []
        );

    const closeDialog = (refresh: boolean) => {
        setCreateOpen(false);
        setEditId(null);

        if (refresh) {
            getData();
        }
    }

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <>
            <Grid item sx={{ pb: 1 }}>
                <Typography variant="h1">Teams</Typography>
            </Grid>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justifyContent="flex-end">
                            <Grid item>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <TextField
                                            autoComplete='off'
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                            onChange={debouncedhandleSearch}
                                            placeholder="Search..."
                                            InputProps={{
                                                endAdornment: <SearchIcon sx={{ color: '#B5ADC4' }} />,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button aria-label={"Add New Team"} variant="contained" color="primary" onClick={() => setCreateOpen(true)}>Create New Team</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <ResponsiveTable
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="teams table"
                            >
                                <SortableTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                    lastCells={<TableCell />}
                                />
                                <TableBody>
                                    {(!loading && results) && results.map((row: TeamListViewModel) => {
                                        return (<TeamTableRow key={row.id} row={row} archiveTeam={setArchiveTeamId} editTeam={setEditId} />);
                                    })
                                    }
                                    {count <= 0 && !loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                        </TableRow>
                                    }
                                    {count > 0 && emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} />
                                        </TableRow>
                                    )}
                                    {loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <CreateTeam open={createOpen} onClose={closeDialog} />
                <EditTeam open={editId !== null} teamId={editId!} onClose={closeDialog}/>
                <WarningDialog open={archiveTeamId !== null} close={() => setArchiveTeamId(null)} buttonText='Remove' text='This will remove the team and its members will have to be added to another team.' okClick={archiveTeam} />
            </Paper>
        </>
    );
}