import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import Button from '@mui/material/Button';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import ColouredRow from '../Utilities/ColouredRow';
import EditTeamViewModel from './Viewmodels/EditTeamViewModel';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import ManagerDropdownViewModel from './Viewmodels/ManagerDropdownViewModel';

interface IProps {
    open: boolean;
    teamId: number;
    onClose: (refresh: boolean) => void;
}

export default function EditTeam(props: IProps) {
    const { open, teamId, onClose } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    const [defaultTeam, setDefaultTeam] = React.useState(true);

    const [managerOptions, setManagerOptions] = React.useState<ManagerDropdownViewModel[]>([]);
    const [memberOptions, setMemberOptions] = React.useState<DropdownViewModel[]>([]);
    const [otherManagers, setOtherManagers] = React.useState<string[]>([]);
    const [filteredManagers, setFilteredManagers] = React.useState<ManagerDropdownViewModel[]>([]);
    const [filteredMembers, setFilteredMembers] = React.useState<DropdownViewModel[]>([]);
    const [team, setTeam] = React.useState<EditTeamViewModel>(new EditTeamViewModel());

    React.useEffect(() => {
        if (teamId > 0) {
            getData();
        }
    }, [teamId]);

    React.useEffect(() => {
        let filtered = [...managerOptions];
        if (team.managers.length !== 0) {
            filtered = filtered.filter(f => team.managers.findIndex(i => i.id === f.id) <= -1);
        }
        setFilteredManagers(filtered);
    }, [team.managers, managerOptions]);

    React.useEffect(() => {
        let filtered = [...memberOptions];
        if (team.members.length !== 0) {
            filtered = filtered.filter(f => team.members.findIndex(i => i.id === f.id) <= -1);
        }
        setFilteredMembers(filtered);
    }, [memberOptions, team.members]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Team/GetTeamMembers?teamId=${teamId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setTeam((prev) => ({
                    ...prev,
                    members: data
                }))
            }
        );

        crabFetch(`Team/GetStaffDropdown?includeManagers=${true}&teamId=${teamId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setMemberOptions(data);
            }
        );

        crabFetch(`Team/GetTeamManagers?teamId=${teamId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setTeam((prev) => ({
                    ...prev,
                    managers: data
                }))
            }
        );

        crabFetch(`Team/GetOtherTeamManagers?teamId=${teamId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setOtherManagers(data);
            }
        );

        crabFetch(`Team/GetTeamName?teamId=${teamId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.Text,
            (data: string) => {
                setTeam((prev) => ({ ...prev, name: data }))
            }
        );

        crabFetch(`Team/GetStaffDropdown?includeManagers=${false}&teamId=${teamId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: ManagerDropdownViewModel[]) => {
                setManagerOptions(data);
            }
        );

        crabFetch(`Team/ReturnIsDefaultTeam?teamId=${teamId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setDefaultTeam(data)
            }
        );
    }

    const addMember = (e: React.ChangeEvent<HTMLInputElement>) => {
        const filtered = [...filteredMembers];
        const newMembers = [...team.members];
        const member = filtered.findIndex(f => f.id === e.target.value);

        newMembers.push(filtered[member]);


        if (team.managers.some(s => s.id === e.target.value)) {
            const managersNew = [...team.managers];
            const memb = managersNew.findIndex(f => f.id === e.target.value);
            managersNew.splice(memb, 1);
            setTeam((prev) => ({
                ...prev,
                managers: managersNew
            }))
        }

        filtered.splice(member, 1);

        // remove from other section if selected into one section
        if (filteredManagers.some(s => s.id === e.target.value)) {
            const filtered = [...managerOptions];
            const member = filtered.findIndex(f => f.id === e.target.value);
            filtered.splice(member, 1);
            setManagerOptions(filtered);
        }

        setTeam((prev) => ({
            ...prev,
            members: newMembers
        }))
        setFilteredMembers(filtered);
    }

    const addManager = (e: React.ChangeEvent<HTMLInputElement>) => {
        const filtered = [...filteredManagers];
        const newMembers = [...team.managers];
        const member = filtered.findIndex(f => f.id === e.target.value);

        newMembers.push({... filtered[member], MemberOfTeamId: null});
        filtered.splice(member, 1);

        // remove from other section if selected into one section
        if (filteredMembers.some(s => s.id === e.target.value)) {
            const filtered = [...memberOptions];
            const member = filtered.findIndex(f => f.id === e.target.value);
            filtered.splice(member, 1);
            setMemberOptions(filtered);
        }

        if (team.members.some(s => s.id === e.target.value)) {
            const mem = team.members.find(f => f.id === e.target.value);
            setTeam((prev) => ({ ...prev, members: prev.members.filter(f => f.id !== e.target.value) }));
            const list = [...filteredMembers];
            list.push(mem!);
            setFilteredMembers(list);
        }


        setTeam((prev) => ({
            ...prev,
            managers: newMembers
        }))
        setFilteredManagers(filtered);
    }

    const removeMember = (id: string) => {
        const newMembers = [...team.members];
        const member = newMembers.findIndex(f => f.id === id);

        var managesAnotherTeam = otherManagers.find(e => e === id);

        if (newMembers[member] !== undefined && managesAnotherTeam === undefined) {
            const filteredManagersCopy = [...filteredManagers];
            filteredManagersCopy.push({ ...newMembers[member], MemberOfTeamId: null });
            setManagerOptions(filteredManagersCopy);
        }

        if (newMembers[member] !== undefined) {
            const filteredMembersCopy = [...filteredMembers];
            filteredMembersCopy.push(newMembers[member]);
            setMemberOptions(filteredMembersCopy);
            newMembers.splice(member, 1);
            setTeam((prev) => ({ ...prev, members: newMembers }))
        }
    }

    const removeManager = (id: string) => {
        const newManagers = [...team.managers];
        const memberIndex = newManagers.findIndex(f => f.id === id);

        if (memberIndex == -1) {
            //Unsure how this would happen, something went very wrong.
            return;
        }

        const filteredManagersCopy = [...filteredManagers];
        filteredManagersCopy.push(newManagers[memberIndex]);
        setManagerOptions(filteredManagersCopy);

        //If we're a member of another team or this isn't the default team, just remove manager and add to the list of possible members.
        //Otherwise, we should add this person as a member of the default team.
        if (newManagers[memberIndex].MemberOfTeamId != null || !defaultTeam) {
            const filteredMembersCopy = [...filteredMembers];
            filteredMembersCopy.push(newManagers[memberIndex]);
            setMemberOptions(filteredMembersCopy);

            newManagers.splice(memberIndex, 1)
            setTeam((prev) => ({ ...prev, managers: newManagers }))
        }
        else {
            const newMembers = [...team.members]
            newMembers.push(newManagers[memberIndex])

            newManagers.splice(memberIndex, 1)
            setTeam((prev) => ({...prev, members: newMembers, managers: newManagers}))
        }
    }

    const submit = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Team/EditTeam?teamId=${teamId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(team)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully edited team members`);
                    onClose(true);
                    setLoading(false);
                    getData();
                }
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }


    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            fullWidth
            maxWidth='lg'
        >
            <DialogTitle>Edit Team</DialogTitle>
            <DialogContent>
                <Grid container spacing={1} sx={{ mb: 1 }}>
                    <Grid item xs={12}>
                        <Typography variant="h2" gutterBottom>Name</Typography>
                        <TextField autoComplete='off' value={team.name} onChange={(e) => setTeam((prev) => ({ ...prev, name: e.target.value }))} name="name" required fullWidth size="small" />
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="warning">Adding members to this team will remove them from their current team.</Alert>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {filteredMembers.length > 0 &&
                                    <>
                                        <Typography variant="h2" gutterBottom>Add Team Member:</Typography>
                                        <TextField autoComplete='off' select name="addMember" required fullWidth size="small" onChange={addMember}>
                                            {filteredMembers.map(item => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>)}
                                        </TextField>
                                    </>
                                }
                                {filteredMembers.length === 0 &&
                                    <>
                                        <Typography variant="h2" gutterBottom>Members</Typography>
                                        <TextField autoComplete='off' disabled size='small' fullWidth defaultValue='No unassigned staff' />
                                    </>
                                }
                            </Grid>
                            {team.members.map(item =>
                                <Grid item xs={12} key={'member' + item.id}>
                                    <ColouredRow colour={'#456FEC'}>
                                        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="h2">{item.label}</Typography>
                                            </Grid>
                                            {!defaultTeam &&
                                                <Grid item>
                                                    <Button variant="contained" onClick={() => removeMember(item.id as string)} color="error" fullWidth>Remove</Button>
                                                </Grid>
                                            }
                                        </Grid>
                                    </ColouredRow>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            {filteredManagers.length > 0 &&
                                <Grid item xs={12}>
                                    <Typography variant="h2" gutterBottom>Add Team Manager:</Typography>
                                    <TextField autoComplete='off' select name="addMember" required fullWidth size="small" onChange={addManager}>
                                        {filteredManagers.map(item => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>)}
                                    </TextField>
                                </Grid>
                            }
                            {filteredManagers.length === 0 &&
                                <Grid item xs={12}>
                                    <Typography variant="h2" gutterBottom>Managers</Typography>
                                    <TextField autoComplete='off' disabled size='small' fullWidth defaultValue='No unassigned staff' />
                                </Grid>
                            }
                            {team.managers.length > 0 && team.managers.map(item =>
                                <Grid item xs={12} key={'member' + item.id}>
                                    <ColouredRow colour={'#456FEC'}>
                                        <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="h2">{item.label}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" onClick={() => removeManager(item.id as string)} color="error" fullWidth>Remove</Button>
                                            </Grid>
                                        </Grid>
                                    </ColouredRow>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <LoadingButton loading={loading} variant="contained" onClick={submit} fullWidth>Save Changes</LoadingButton>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button disabled={loading} variant="outlined" onClick={() => onClose(false)} color="error" fullWidth>Cancel</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}