import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PatternDropdownViewModel from './Viewmodels/PatternDropdownViewModel';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'moment/locale/en-gb';
import SetShiftPatternViewModel from './Viewmodels/SetShiftPatternViewModel';
import LoadingButton from '@mui/lab/LoadingButton';
import dayjs, { Dayjs } from 'dayjs';
import { DatePickerElement, FormContainer } from 'react-hook-form-mui';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean, success: boolean) => void;
    userId: string[];
    hasSelectedAll?: boolean;
    teamFilter?: number;
}

export default function AssignShiftPatternDialog(props: IProps) {
    const { open, onClose, userId, hasSelectedAll, teamFilter } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [shiftPatterns, setShiftPatterns] = React.useState<PatternDropdownViewModel[]>([]);
    const [pattern, setPattern] = React.useState(new PatternDropdownViewModel());
    const [startWeek, setStartWeek] = React.useState(0);
    const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();

        const restrictId = userId && userId.length === 1 ? userId[0] : '';

        crabFetch('Shift/GetShiftPatternDropdown?userId=' + restrictId, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setShiftPatterns(data);
            }
        );
    }


    const submit = async () => {
        const token = await authService.getAccessToken();
        setSubmitting(true);

        let date: Date | null = null;

        if (startDate) {
            date = startDate.toDate();
        }

        var form = new SetShiftPatternViewModel();

        form.userIds = userId;
        form.pattern = pattern.id;
        form.startingWeek = startWeek;
        form.date = date;
        if (hasSelectedAll != null) {
            form.hasSelectedAll = hasSelectedAll;
        }
        else {
            form.hasSelectedAll = false;
        }

        if (teamFilter != null) {
            form.teamFilter = teamFilter;
        }
        else {
            form.teamFilter = 0;
        }

        crabFetch(`Shift/AssignShiftPattern`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                setSubmitting(false);

                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully assigned shift pattern`);
                    closeDialog(true, true);
                }
            },
            (error: any) => {
                setSubmitting(false);
                show('error', error);
            }
        );
    }

    const onPatternChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const patternChoice = shiftPatterns.find(f => f.id === parseInt(e.target.value));

        if (patternChoice) {
            setPattern(patternChoice);
            setStartWeek(0);
        }
    }

    const closeDialog = (refresh: boolean, success: boolean) => {
        setPattern(new PatternDropdownViewModel());
        setStartWeek(0);
        onClose(refresh, success);
    }

    const today = new Date();

    return (
        <Dialog
            open={open}
            onClose={() => closeDialog(false, false)}
            fullWidth
        >
            <FormContainer>
                <DialogTitle>Assign Shift Pattern</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h2" gutterBottom>Shift Pattern:</Typography>
                            <TextField autoComplete='off' select name="shiftPattern" required fullWidth size="small"
                                value={pattern.id}
                                onChange={onPatternChange}
                            >
                                {shiftPatterns.map(item => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>)}
                            </TextField>
                        </Grid>
                        {pattern.numberOfWeeks > 1 &&
                            <Grid item xs={12}>
                                <Typography variant="h2" gutterBottom>Rotation Starting Week:</Typography>
                                <TextField autoComplete='off' select name="startWeek" required fullWidth size="small"
                                    value={startWeek}
                                    onChange={(e) => setStartWeek(parseInt(e.target.value))}
                                >
                                    {Array.from({ length: pattern.numberOfWeeks }, (_, i) => i + 1).map(item =>
                                        <MenuItem key={'week' + item} value={item}>{item}</MenuItem>
                                    )}
                                </TextField>
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <Typography variant="h2" gutterBottom>Start Date:</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                                <DatePickerElement minDate={dayjs(today)} onChange={(newValue: any) => { setStartDate(newValue); }} name="start" required inputProps={{ fullWidth: true, size: "small" }} textReadOnly />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <LoadingButton loading={submitting} variant="contained" onClick={submit} fullWidth color="primary" disabled={pattern.id === 0 || !startDate}>Confirm Assignment</LoadingButton>
                        <Button variant="outlined" onClick={() => closeDialog(false, false)} color="error" fullWidth disabled={submitting}>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer >
        </Dialog>
    );
}