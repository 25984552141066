import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useParams, Navigate } from 'react-router';
import Alert from '@mui/material/Alert';
import Bugsnag from '@bugsnag/js';
import Paper from '@mui/material/Paper';
import { FormContainer, PasswordElement, PasswordRepeatElement } from 'react-hook-form-mui';
import InputLabel from '@mui/material/InputLabel';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';


export default function RegisterConfirmation() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { userId, code } = useParams();

    const [redirect, setRedirect] = React.useState(false);
    const [exists, setExists] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [currentError, setCurrentError] = React.useState('');

    React.useEffect(() => {
        checkExists();
    }, []);

    const checkExists = async () => {
        crabFetch(`User/CheckUserExists?id=${userId}`, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setExists(data);
            },
            (error: any) => {
                Bugsnag.notify(error);
            }
        )
    }

    const createUser = async (form: any) => {
        crabFetch('User/SetPassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: userId, code: code, password: form.password })
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) {
                    setCurrentError(data);
                    setShowAlert(true);
                } else {
                    updateAuthState();
                    setRedirect(true);
                    setShowAlert(false);
                }
            },
            (error: any) => {
                Bugsnag.notify(error);
            }
        )
    }

    const updateAuthState = async () => {
        await authService.updateUser();
    }

    return (
        <>
            {
                redirect ? <Navigate to="/" />
                    :
                    <>
                        {exists ?
                            <FormContainer
                                defaultValues={{
                                    password: '', confirmPassword: ''
                                }}
                                onSuccess={createUser}
                            >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Grid item sx={{ pb: 1 }}>
                                            <Typography variant="h1">Create Password</Typography>
                                        </Grid>
                                        <Paper sx={{ padding: 2 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="password" shrink>Password</InputLabel>
                                                    <PasswordElement name="password" fullWidth size="small"
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <InputLabel htmlFor="confirmPassword" shrink>Confirm Password</InputLabel>
                                                    <PasswordRepeatElement name="confirmPassword" fullWidth size="small" passwordFieldName="password" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button fullWidth variant="contained" color="primary" type="submit">Confirm</Button>
                                                </Grid>
                                                {showAlert &&
                                                    <Grid item xs={12}>
                                                        <Alert severity="warning">{currentError}</Alert>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid container spacing={2} sx={{ pt: 2 }}>
                                                <Grid item xs={12}>
                                                    <Typography variant='body1'>Your password must have:
                                                        <ul>
                                                            <li>8 or more characters</li>
                                                            <li>Upper and lower case letters</li>
                                                            <li>At least one number</li>
                                                        </ul>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </FormContainer>
                            :
                            <Alert severity="warning">Invalid link, user does not exist.</Alert>
                        }
                    </>
            }
        </>
    );
}