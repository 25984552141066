import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import ShiftListViewModel from './Viewmodels/ShiftListViewModel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ShiftsTableRow from './ShiftsTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import ModifyShiftPatternDialog from './ModifyShiftPatternDialog';
import debounce from 'lodash/debounce';
import SearchIcon from '@mui/icons-material/Search';

const headCells: HeadCell<ShiftListViewModel>[] = [
    { id: 'name', property: 'Name', align: "left", disablePadding: false, label: 'Shift Name' },
    { id: 'activeStaff', property: 'ActiveStaff', align: "right", disablePadding: false, label: 'Active Staff on Shift' }
];

export default function ShiftsTable() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof ShiftListViewModel>('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [results, setResults] = React.useState<ShiftListViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [editingRow, setEditingRow] = React.useState<number | null>(null);

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {
        searchData();
    }, [search]);

    React.useEffect(() => {
        if (editingRow)
            setOpenDialog(true);
    }, [editingRow]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Shift/GetShiftsTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ShiftListViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const searchData = () => {
        if (page === 0) {
            getData();
        }

        setPage(0);
    }

    const debouncedhandleSearch =
        React.useCallback!(
            debounce(handleSearch, 600),
            []
        );

    const closeDialog = (refresh: boolean) => {
        setOpenDialog(false);
        setEditingRow(null);

        if (refresh)
            searchData();
    }

    const editRow = (id: number) => {
        setEditingRow(id);
    }

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <>
            <Grid item sx={{ pb: 1 }}>
                <Typography variant="h1">Your Shifts</Typography>
            </Grid>
            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justifyContent="flex-end">
                            <Grid item>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <TextField
                                            autoComplete='off'
                                            color="primary"
                                            size="small"
                                            variant="outlined"
                                            onChange={debouncedhandleSearch}
                                            placeholder="Search..."
                                            InputProps={{
                                                endAdornment: <SearchIcon sx={{ color: '#B5ADC4' }} />,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button aria-label={"Add New Shift Pattern"} variant="contained" color="primary" onClick={() => setOpenDialog(true)}>Create New Shift Pattern</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <ResponsiveTable
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="shifts table"
                            >
                                <SortableTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                    lastCells={<TableCell />}
                                />
                                <TableBody>
                                    {(!loading && results) && results.map((row: ShiftListViewModel) => {
                                        return (<ShiftsTableRow key={row.id} row={row} edit={editRow} />);
                                    })
                                    }
                                    {count <= 0 && !loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                        </TableRow>
                                    }
                                    {count > 0 && emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} />
                                        </TableRow>
                                    )}
                                    {loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ModifyShiftPatternDialog open={openDialog} onClose={closeDialog} shiftPatternId={editingRow} showDefault />
            </Paper>
        </>



    );
}