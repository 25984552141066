import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, TextFieldElement, SelectElement } from 'react-hook-form-mui';
import ProfileViewModel from '../Profile/Viewmodels/ProfileViewModel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertContext } from '../Contexts/AlertContext';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    userId?: string;
    user?: ProfileViewModel;
    admin?: boolean;
}

export default function CreateEditUser(props: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, userId, user, admin } = props;
    const [loading, setLoading] = React.useState(false);
    const [roles, setRoles] = React.useState<DropdownViewModel[]>([]);
    const [userRole, setUserRole] = React.useState('');

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setUserRole(user.role);

        crabFetch(`User/GetRolesDropdown`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                setLoading(false);
                if (admin && admin === true) setRoles(data.filter(f => f.label === 'Admin'))
                else setRoles(data);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        let url = '';
        if (user) url = `Profile/UpdateProfile?userId=${userId}`;
        else url = 'User/CreateAdmin';

        crabFetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', user ? "Successfully updated profile" : "Successfully created user");
                    onClose(true);
                    setLoading(false);
                }
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

  

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <FormContainer
                defaultValues={
                    user ?
                        { firstName: user.firstName, lastName: user.lastName, email: user.email, roleId: user.roleId }
                        :
                        { firstName: '', lastName: '', email: '', roleId: '' }
                }
                onSuccess={submit}
            >
                <DialogTitle>{user ? "Edit Profile" : "Create Admin"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="firstName" shrink>Forename</InputLabel>
                            <TextFieldElement autoComplete='off' name="firstName" required fullWidth size="small" validation={{ validate: value => value.trim() !== "" || "Field cannot be empty" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="lastName" shrink>Surname</InputLabel>
                            <TextFieldElement autoComplete='off' name="lastName" required fullWidth size="small" validation={{ validate: value => value.trim() !== "" || "Field cannot be empty" }} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="email" shrink>Email</InputLabel>
                            <TextFieldElement autoComplete='off' name="email" required fullWidth size="small" validation={{ validate: value => value.trim() !== "" || "Field cannot be empty" }} />
                        </Grid>
                        {userRole === 'Admin' &&
                            <Grid item xs={12}>
                                <InputLabel htmlFor="roleId" shrink>Role</InputLabel>
                                <SelectElement
                                    name="roleId"
                                    required
                                    fullWidth
                                    size="small"
                                    options={userRole === 'Admin' ? roles : undefined}
                                    InputProps={{
                                        disabled: loading,
                                        startAdornment: (
                                            !loading ? null : <CircularProgress size={20} />
                                        )
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                    <DialogActions>
                        <LoadingButton loading={loading} variant="contained" type="submit">{user ? "Edit" : "Add"}</LoadingButton>
                        <Button disabled={loading} onClick={() => onClose(false)} color="error">Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}