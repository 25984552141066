import * as React from 'react';
import HolidaysTableViewModel from './Viewmodels/HolidaysTableViewModel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

interface IProps {
    row: HolidaysTableViewModel;
    edit: (id: number) => void;
    remove: (id: number) => void;
    isBankHoliday: boolean;
}

export default function CompanyHolidaysTableRow(props: IProps) {
    const { row, edit, isBankHoliday, remove } = props;

    return (
        <TableRow>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.date ? new Date(row.date).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" }) : ''}</TableCell>
            <TableCell align="right">
                <Grid container spacing={1} justifyContent='flex-end'>
                    {!isBankHoliday &&
                        < Grid item>
                            <Button size='small' variant='contained' onClick={() => edit(row.id)}>Edit</Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button size='small' color="error" variant='contained' onClick={() => remove(row.id)}>Remove</Button>
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );
}