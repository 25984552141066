export default class OnboardingViewModel {
    public id: string = '';
    public companyName: string = '';
    public adminFirstName: string = '';
    public adminSurename: string = '';
    public country: number = -1;

    public holidayFromDay: number = 1;
    public holidayFromMonth: number = 1;

    public entitlement: number = 28;
    public workingWeekStart: number = 1;

    public fullTimeHours: number = 40;
}