import * as React from 'react';
import CompaniesTableViewModel from '../../Companies/Viewmodels/CompaniesTableViewModel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import { AdminContext } from '../../Contexts/AdminContext';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import authService from '../../api-authorization/AuthorizeService';
import { AlertContext } from '../../Contexts/AlertContext';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface IProps {
    row: CompaniesTableViewModel;
}

export default function CompaniesTableRow({ row }: IProps) {
    const { changeCompany } = React.useContext(AdminContext);

    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);


    const confirm = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        crabFetch(`Company/ResendEmailConfirmation?email=${row.email}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully sent confimation Email`);
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    return (
        <TableRow>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.subscriptionStatus}</TableCell>
            <TableCell>{row.onboardStatus ? "Onboarded" : "Not Onboarded"}</TableCell>
            <TableCell>{row.staffCount}</TableCell>
            <TableCell align="right">
                {row.onboardStatus &&
                    <Button variant="contained" size="small" onClick={() => changeCompany(row.id)}>View</Button>
                }
                {!row.onboardStatus &&
                    <LoadingButton variant="contained" loading={loading} size="small" onClick={confirm}>Re-send Invite</LoadingButton>
                }
            </TableCell>
        </TableRow>
    );
}