import * as React from 'react';
import EventTableViewModel from './Viewmodels/EventTypeTableViewModel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import Button from '@mui/material/Button';
import CreateEventType from './CreateEventType';
import * as Icons from "@mui/icons-material";
import Grid from '@mui/material/Grid';

interface IProps {
    row: EventTableViewModel;
    refresh: () => void;
}

export default function EventTableRow({ row, refresh }: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    const toggleArchive = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const method = row.archived ? 'UnArchiveEventType' : 'ArchiveEventType';

        crabFetch(`Event/${method}?id=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${row.archived ? 're-enabled' : 'archived'} Event Type`);
                    refresh();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const handleCloseEdit = () => {
        setOpenEdit(false);
        refresh();
    };

    const handleOpenEdit = () => {
        setOpenEdit(true);
    };

    const Icon = React.useMemo(() => {
        const icon = Icons[row.icon]
        return icon;
    }, [row.icon]);

    return (
        <TableRow>
            <TableCell>{row.name}</TableCell>
            <TableCell>
                {Icon && <Icon sx={{ color: row.color }} />}
            </TableCell>
            <TableCell>{row.archived ? "Archived" : "Active"}</TableCell>
            <TableCell align="right">
                <Grid container spacing={1} justifyContent='flex-end' >
                    <Grid item>
                        <Button size='small' disabled={loading || row.archived} variant={'contained'} onClick={handleOpenEdit}>Edit</Button>
                    </Grid>
                    <Grid item>
                        <Button size='small' disabled={loading} variant={'contained'} onClick={toggleArchive}>{row.archived ? "Unarchive" : "Archive"}</Button>
                    </Grid>
                </Grid>
            </TableCell>
            <CreateEventType open={openEdit} onClose={handleCloseEdit} eventTypeEdit={row} eventTypeId={row.id} />
        </TableRow>
    );
}