import * as React from 'react';
import authService from '../../api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import EventListViewModel from '../Viewmodels/EventListViewModel';
import ColouredRow from '../../Utilities/ColouredRow';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import CircularGaugeChart from '../../Utilities/CircularGaugeChart';
import TeamNumberViewModel from '../Viewmodels/TeamNumberViewModel';
import * as Icons from "@mui/icons-material";

interface IProps {
    teamView: number | undefined;
    dateFilter: Date;
}

export default function EventList(props: IProps) {
    const { teamView, dateFilter } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [events, setEvents] = React.useState<EventListViewModel[]>([]);
    const [teamNumbers, setTeamNumbers] = React.useState<TeamNumberViewModel[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [page, setPage] = React.useState(0);

    React.useEffect(() => {
        getData();
    }, [teamView, dateFilter]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        const date = new Date(dateFilter);
        date.setHours(12); // make sure it is utc time

        const offset = date.getTimezoneOffset();

        crabFetch(`Event/GetStaffEvents?dateFilter=${date.toISOString()}${teamView ? `&teamId=${teamView}` : ``}&clientOffset=${offset}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: EventListViewModel[]) => {
                setLoading(false);
                setEvents(data);
            }
        );

        crabFetch(`Team/GetTeamNumbers?dateFilter=${date.toISOString()}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: TeamNumberViewModel[]) => {
                setTeamNumbers(data);
            }
        );
    }

    const teamData = React.useMemo(() => {
        if (teamNumbers.length > 0) {

            const grouped: any = Object.entries(
                events.reduce((acc: any, event) => {
                    if (!acc[event.team]) {
                        acc[event.team] = [];
                    }
                    acc[event.team].push(event);
                    return acc;
                }, {})
            ).map(([team, teamEvents]) => ({ team, teamEvents }));

            const teamCounts = grouped.map((item: any) => ({ team: item.team, count: item.teamEvents.length }));

            var result = teamNumbers.map(item => {
                
                if (item.name === "All Staff") {
                    const eventsCount = events.reduce((set, c) => {
                        set.add(c.id);
                        return set;
                    }, new Set());

                    var percent = item.memberCount != 0 ? Math.round((item.memberCount - eventsCount.size) / item.memberCount * 100) ?? 0 : null;

                    return { team: item.name, count: item.memberCount, presentCount: item.memberCount - (eventsCount.size), percentage:  percent && isNaN(percent) ? 100 : percent };
                }
                else {
                    const teamWithEvents = teamCounts.find((f: any) => f.team === item.name)?.count ?? 0;

                    return {
                        team: item.name, count: item.memberCount, presentCount:
                            item.memberCount - teamWithEvents, percentage: item.memberCount != 0 ? Math.round((item.memberCount - teamWithEvents) / item.memberCount * 100) : null
                    };
                }
            });

            return result;
        }
        return [];
    }, [events, teamNumbers]);

    const eventList = React.useMemo(() => {
        let sorted_arr = events.sort((a, b) => (a.eventId ?? 0) - (b.eventId ?? 0));

        let duplicates: EventListViewModel[] = [];
        let result: EventListViewModel[] = [];
        for (let i = 0; i < sorted_arr.length; i++) {
            var item = sorted_arr[i]
            //Find other items with matching eventIds
            if (item.eventId && sorted_arr.some(e => e.eventId == item.eventId && e.id !== item.id)) {
                if (!duplicates.some(e => e.eventId == item.eventId)) {
                    duplicates.push(item)
                }
            } else {
                result.push(item)
            }
        }

        duplicates.forEach(d => result.unshift(d));
        result.forEach(r => r.userName = r.eventId ? 'Company Event' : r.userName)

        return result;
    }, [events, dateFilter]);

    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h2">Events for {dateFilter.toLocaleDateString('en-gb', options)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ border: 1, borderColor: 'primary.main' }}>
                    <Grid container spacing={{ xs: 1, xl: 2 }} alignItems="center" justifyContent="center">
                        {loading &&
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        }
                        {!loading && eventList.filter(w => w.eventId !== 0).length <= 0 &&
                            <Grid item>
                                <Typography>No Events</Typography>
                            </Grid>
                        }
                        {eventList.slice(page * 3, page * 3 + 3).map((item, index) => {
                            const split = item.userName.split(' ');
                            const Icon = Icons[item.icon] ?? <React.Fragment />;
                            return <Grid item xs={12} key={'dayEvent_' + index}>
                                <ColouredRow colour={item.colour} backgroundColour={'#F2F4F8'}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs={12} sm={6} lg={3}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs='auto'>
                                                    {Icon && <Icon style={{ color: item.colour, verticalAlign: "middle" }} />}
                                                </Grid>
                                                <Grid item xs='auto'>
                                                    <Typography color={item.colour}>{item.name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={true}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs='auto'>
                                                    <Avatar sizes="small">{split[0].charAt(0)}{split[1].charAt(0)}</Avatar>
                                                </Grid>
                                                <Grid item xs='auto'>
                                                    <Typography sx={{ color: 'primary.main' }}>{item.userName}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Typography>{item.note}</Typography>
                                        </Grid>
                                    </Grid>
                                </ColouredRow>
                            </Grid>;
                        })}
                        {!loading && eventList.filter(w => w.eventId !== 0).length > 0 &&
                            <Grid container item justifyContent="flex-end">
                                <Pagination count={Math.ceil(eventList.filter(w => w.eventId !== 0).length / 3)} siblingCount={0} page={page + 1} onChange={(event: React.ChangeEvent<unknown>, value: number) => setPage(value - 1)} />
                            </Grid>
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h2">Team attendance for {dateFilter.toLocaleDateString('en-gb', options)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ border: 1, borderColor: 'primary.main' }}>
                    <Grid container spacing={{ xs: 1, xl: 2 }} alignItems="center">
                        {teamData && teamData.length > 0 && teamData.map(item =>
                            <Grid item key={'teamStat' + item.team} xs={12} lg={6}>
                                <Grid container spacing={{ xs: 1, xl: 2 }} alignItems="center">
                                    <Grid item xs="auto">
                                        <CircularGaugeChart percentage={item.percentage} size={80} />
                                    </Grid>
                                    <Grid item xs>
                                        <Grid container spacing={{ xs: 1, xl: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography variant="h3" >{item.team}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography>
                                                    {item.count > 0 ? `${item.presentCount} out of ${item.count} members.` : `None scheduled.`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}