import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import ShiftPatternViewModel from '../Shifts/Viewmodels/ShiftPatternViewModel';
import ShiftWeekViewModel from '../Shifts/Viewmodels/ShiftWeekViewModel';
import ShiftHistoryTable from '../Shifts/ShiftHistoryTable';
import AssignShiftPatternDialog from '../Shifts/AssignShiftPatternDialog';
import ModifyShiftPatternDialog from '../Shifts/ModifyShiftPatternDialog';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import dayjs from 'dayjs';

interface IProps {
    userId: string;
}

export default function MemberShiftManagement(props: IProps) {
    const { userId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [existingPattern, setExistingPattern] = React.useState<ShiftPatternViewModel>(new ShiftPatternViewModel());
    const [groupedWeeks, setGroupedWeeks] = React.useState<ShiftWeekViewModel[]>([]);
    const [openAssign, setOpenAssign] = React.useState(false);
    const [openModify, setOpenModify] = React.useState(false);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (userId.length > 0)
            getData();
    }, [userId]);

    React.useEffect(() => {
        setLoading(true);
        if (existingPattern.weeks && existingPattern.weeks.length > 0) {
            const weeks = [...existingPattern.weeks];

            for (var i = 0; i < weeks.length; i++) {
                for (var j = 0; j < 7; j++) {
                    if (weeks[i].days.findIndex(f => f.dayIndex === j) <= -1) {
                        weeks[i].days.push({
                            id: null,
                            dayIndex: j,
                            weekIndex: weeks[i].weekIndex,
                            active: false,
                            startTime: null,
                            endTime: null
                        });
                    }
                }

                weeks[i].days = weeks[i].days.sort((a, b) => {
                    if (a.dayIndex > b.dayIndex) {
                        return 1;
                    } else if (a.dayIndex < b.dayIndex) {
                        return -1;
                    }

                    return 0;
                });
            }

            setGroupedWeeks(weeks);
        }
        setLoading(false);
    }, [existingPattern]);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Shift/GetCurrentShiftPattern?userId=${userId}`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setExistingPattern(data);
            }
        );
    }

    const closeDialogs = (refresh: boolean) => {
        setOpenAssign(false);
        setOpenModify(false);
        setOpenHistory(false);

        if (refresh) {
            getData();
        }
    }

    const closeAssignDialog = (refresh: boolean, success: boolean) => {
        setOpenAssign(false);

        if (refresh) {
            getData();
        }
    }

    const getDayLabel = (index: number) => {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return days[index];
    };

    return (
        <>
            <Typography sx={{ pb: 1 }} variant="h2">Shift Pattern</Typography>
            <Paper>
                <Grid container justifyContent="space-between" spacing={2} sx={{ pl: 2, pr: 2 }}>
                    {!loading && groupedWeeks.length !== 0 &&
                        <Grid item xs={12}>
                            <TableContainer>
                                <ResponsiveTable
                                    aria-labelledby="tableTitle"
                                    size={'medium'}
                                    aria-label="user table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            {[0, 1, 2, 3, 4, 5, 6].map((day, index) => (
                                                <TableCell align='left' key={index}>
                                                    {getDayLabel((index + existingPattern.workingWeekStart) % 7)}
                                                </TableCell>
                                            ))}
                                            <TableCell align='right'>Total Hours</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(groupedWeeks && groupedWeeks.length > 0 && !loading) && groupedWeeks.map((week, index) =>
                                            <TableRow key={'week' + index}>
                                                <TableCell>Week {index + 1}</TableCell>
                                                {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
                                                    // This calculates the correct starting day of the week for
                                                    // this user and displayed the days of the week accordingly 
                                                    const adjustedIndex = (dayIndex + existingPattern.workingWeekStart) % 7;
                                                    const day = week.days.find((d) => d.dayIndex === adjustedIndex);
                                                    return (
                                                        <TableCell align='left' key={adjustedIndex + 'week' + index}>
                                                            {(day?.startTime && day?.endTime) ? dayjs(day.startTime).format('HH:mm') + ' - ' + dayjs(day.endTime).format('HH:mm') : 'None'}
                                                        </TableCell>
                                                    );
                                                })}
                                                <TableCell align='right'>{week.totalWorkingHour}</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </ResponsiveTable>
                            </TableContainer>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm="auto">
                                <Button onClick={() => setOpenHistory(true)}>Click here to see shift pattern history</Button>
                            </Grid>
                            {(existingPattern.viewingUserCanEdit || existingPattern.id === null) && !loading &&
                                <>
                                    <Grid item xs={12} sm={6} md="auto">
                                        <Button variant="contained" color="primary"
                                            disabled={existingPattern === null || !existingPattern.id || existingPattern.id <= 0}
                                            onClick={() => setOpenModify(true)}
                                        >Modify Shift Pattern</Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md="auto">
                                        <Button variant="contained" color="primary" onClick={() => setOpenAssign(true)}>Assign Shift Pattern</Button>
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {openHistory && <ShiftHistoryTable userId={userId} key={'historyTable' + existingPattern.id} onClose={closeDialogs} open={openHistory} />}
                <AssignShiftPatternDialog open={openAssign} onClose={closeAssignDialog} userId={[userId]} />
                {openModify && <ModifyShiftPatternDialog open={openModify} onClose={closeDialogs} userId={userId} shiftPatternId={existingPattern.id} />}
            </Paper>
        </>
    );
}