import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import EntitlementTable from './EntitlementTable';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';

interface IProps {
    userId: string;
}

export default function ManageEntitlement(props: IProps) {
    const { userId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [adjustedEntitlement, setAdjustedEntitlement] = React.useState(0);
    const [currentEntitlement, setCurrentEntitlement] = React.useState(0);
    const [usedEntitlement, setusedEntitlement] = React.useState(0);
    const [yearOptions, setyearOptions] = React.useState<DropdownViewModel[]>([]);
    const [yearFilter, setYearFilter] = React.useState(0);

    React.useEffect(() => {
        if (userId.length > 0 && userId !== '' && yearFilter)
            getData();
    }, [userId, yearFilter]);

    React.useEffect(() => {
        console.table(yearOptions);
    }, [yearOptions]);

    const getData = async () => {
        const token = await authService.getAccessToken();
        let dateFilter = new Date();
        dateFilter.setFullYear(new Date().getFullYear() + yearFilter)

        crabFetch(`Staff/GetAdjustedEntitlement?userId=${userId}&yearFilter=${dateFilter.toISOString()}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setAdjustedEntitlement(data);
            }
        );

        crabFetch(`Staff/GetUsedEntitlement?userId=${userId}&yearFilter=${dateFilter.toISOString()}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setusedEntitlement(data);
            }
        );

        crabFetch(`Staff/GetRemainingEntitlement?userId=${userId}&yearFilter=${dateFilter.toISOString()}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setCurrentEntitlement(data);
            }
        );

        crabFetch(`Company/GetCompanyYearDropdown?userId=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setyearOptions(data);
            }
        );
    }

    const onYearDropdownChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setYearFilter(Number(e.target.value));
    }

    return (
        <>
            <Grid item xs={12}>
                <Paper>
                    <Grid container justifyContent="space-evenly" spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="h2">Entitlement Summary</Typography>
                        </Grid>
                        <Grid item xs={6}>

                            <TextField
                                fullWidth
                                select
                                value={yearFilter}
                                onChange={onYearDropdownChange}
                                size='small'
                            >
                                {yearOptions && yearOptions.map((item) => (
                                    <MenuItem key={'year' + item.id} value={item.id}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </Grid>
                        <Grid container item xs={12} sm={'auto'}>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="center">Total Entitlement for current year</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 64 }} align="center">{adjustedEntitlement ?? 0}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center">Days</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={'auto'}>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="center">Entitlement Used</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center" sx={{ fontSize: 64 }}>{usedEntitlement ?? 0}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center">Days</Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={'auto'}>
                            <Grid item xs={12}>
                                <Typography variant="h5" align="center">Entitlement Remaining</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center" sx={{ fontSize: 64 }}>{currentEntitlement ?? 0}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography align="center">Days</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <EntitlementTable userId={userId} type="Change" refreshSummary={getData} />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <EntitlementTable userId={userId} type="Adjustment" refreshSummary={getData} />
                </Paper>
            </Grid>
        </>
    );
}