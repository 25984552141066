import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import HolidaysTableViewModel from './Viewmodels/HolidaysTableViewModel';
import { AlertContext } from '../Contexts/AlertContext';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import CompanyHolidaysTableRow from './CompanyHolidaysTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import ManageCompanyEvent from './ManageCompanyEvent';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import dayjs, { Dayjs } from 'dayjs';
import { InputLabel } from '@mui/material';
import WarningDialog from '../Utilities/WarningDialog';

const headCells: HeadCell<HolidaysTableViewModel>[] = [
    { id: 'name', property: 'Name', align: "left", disablePadding: false, label: 'Name' },
    { id: 'date', property: 'Date', align: "left", disablePadding: false, label: 'Date' }
];

export default function BankHolidayTable() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof HolidaysTableViewModel>('date');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [results, setResults] = React.useState<HolidaysTableViewModel[]>([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [edit, setEdit] = React.useState<number | null>(null);
    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs().year(dayjs().year()).month(0).date(1));
    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs().year(dayjs().year()).month(11).date(31));
    const [removeHolidayId, setRemoveHolidayId] = React.useState<number | null>(null);

    React.useEffect(() => {
        if (startDate && endDate) {
            getData();
        }
    }, [page, rowsPerPage, order, orderBy, startDate, endDate]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Event/GetCompanyBankHolidaysTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&start=${startDate}&end=${endDate}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const remove = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        crabFetch(`event/removecompanyevent?id=${removeHolidayId}`, {
            method: 'post',
            headers: !token ? { 'content-type': 'application/json; charset=utf-8' } : { 'authorization': `bearer ${token}`, 'content-type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `successfully removed event`);
                    getData();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof HolidaysTableViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeEdit = (refresh: boolean) => {
        if (refresh)
            getData();

        setEdit(null);
    }

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <>
            <Grid item sx={{ pb: 1 }}>
                <Typography variant="h1">Bank Holidays</Typography>
            </Grid>
            <Paper>
                <Grid container spacing={2}>
                    <Grid container item spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                            <Grid item>
                                <InputLabel>Start</InputLabel>
                                <DatePicker
                                    value={startDate}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(newValue: Dayjs | null) => {
                                        setStartDate(newValue);
                                    }} />
                            </Grid>
                            <Grid item>
                                <InputLabel>End</InputLabel>
                                <DatePicker
                                    value={endDate}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(newValue: Dayjs | null) => {
                                        setEndDate(newValue);
                                    }} />

                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <ResponsiveTable
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="holiday table"
                            >
                                <SortableTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                    lastCells={<TableCell />}
                                />
                                <TableBody>
                                    {(!loading && results) && results.map((row: HolidaysTableViewModel) => {
                                        return (<CompanyHolidaysTableRow key={row.id} row={row} edit={setEdit} isBankHoliday={true} remove={setRemoveHolidayId } />);
                                    })
                                    }
                                    {count <= 0 && !loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                        </TableRow>
                                    }
                                    {count > 0 && emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} />
                                        </TableRow>
                                    )}
                                    {loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {edit && <ManageCompanyEvent open={edit !== null && edit > 0} onClose={closeEdit} eventId={edit!} publicHol />}
                <WarningDialog open={removeHolidayId !== null} close={() => setRemoveHolidayId(null)} buttonText='Remove' text='This will remove the holiday for all users' okClick={remove} />
            </Paper >
        </>
    );
}