import * as React from 'react';
import ShiftListViewModel from './Viewmodels/ShiftListViewModel';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';

interface IProps {
    row: ShiftListViewModel;
    edit: (id: number) => void;
}

export default function ShiftsTableRow({ row, edit }: IProps) {

    return (
        <TableRow>
            <TableCell>{row.name}</TableCell>
            <TableCell align="right">{row.activeStaff}</TableCell>
            <TableCell align="right">
                <Button size='small' variant={'contained'} onClick={() => edit(row.id)}>Edit Shift Pattern</Button>
            </TableCell>
        </TableRow>
    );
}