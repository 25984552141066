import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import EntitlementViewModel from './Viewmodels/EntitlementViewModel';

interface IProps {
    row: EntitlementViewModel;
}

export default function EntitlementTableRow(props: IProps) {
    const { row } = props;

    return (
        <TableRow>
            <TableCell>{row.change}</TableCell>
            <TableCell>{row.changedBy}</TableCell>
            <TableCell>{row.goesIntoEffectOn ? new Date(row.goesIntoEffectOn).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" }) : ''}</TableCell>
            <TableCell>{row.reason}</TableCell>
        </TableRow>
    );
}