import * as React from 'react';
import authService from './api-authorization/AuthorizeService';
import Grid from '@mui/material/Grid';
import { AdminContext } from './Contexts/AdminContext';
import CompanyDashboard from './Dashboard/CompanyDashboard';
import Typography from '@mui/material/Typography';
import YourCalendar from './Dashboard/YourCalendar';
import { ResponseType, TokenRefreshContext } from './Contexts/TokenRefreshContext';
import CompaniesTable from './Admin/Dashboard/CompaniesTable';
import Button from '@mui/material/Button';
import ICalLinkDialog from './Dashboard/iCalLinkDialog';
import { OnboardContext } from './Admin/Dashboard/Onboarding';

export default function Dashboard() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { onboarded } = React.useContext(OnboardContext);
    const { currCompany } = React.useContext(AdminContext);
    const [role, setRole] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [teamId, setTeamId] = React.useState(0);
    const [open, setOpen] = React.useState<boolean>(false);
    const [loadingName, setLoadingName] = React.useState<boolean>(false);
    const [loadingTeamId, setLoadingTeamId] = React.useState<boolean>(false);

    React.useEffect(() => {
        getData();
    }, [onboarded])

    const getData = async () => {
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setRole(user.role);
        setLoadingName(true);

        crabFetch(`Staff/GetFirstName`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.Text,
            (data: any) => {
                setFirstName(data);
                setLoadingName(false);
            }
        );

        if (user.role === 'Staff Member') {
            setLoadingTeamId(true);
            crabFetch(`Team/GetTeamId`, {
                method: 'GET',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
            }, ResponseType.JSON,
                (data: number) => {
                    setTeamId(data);
                    setLoadingTeamId(false);
                }
            );
        }
    }

    const onClose = () => {
        setOpen(false);
    };

    const todayDate = new Date();
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <>
            {!loadingName && !loadingTeamId &&
                <>
                    {(role !== 'Staff Member' || (role === 'Staff Member' && teamId > 0)) &&
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item>
                                <Typography variant="h1">Welcome {firstName}, It's {todayDate.toLocaleDateString('en-gb', options)}</Typography>
                            </Grid>

                            {(role !== 'Admin') &&
                                <Grid item>
                                    <Button variant="outlined" size={"small"} onClick={() => setOpen(true)}>Connect teamplanner to your calendar</Button>
                                </Grid>
                            }

                            {(role === 'Admin' && currCompany === null) &&
                                <CompaniesTable />
                            }
                            {(role === 'Company Admin' || (role === 'Admin' && currCompany !== null) || (role === 'Staff Member' && teamId > 0)) &&
                                <CompanyDashboard />
                            }
                        </Grid>
                    }
                    {role === 'Staff Member' && teamId === 0 &&
                        <YourCalendar />
                    }
                    <ICalLinkDialog open={open} onClose={onClose} />
                </>
            }
        </>

    );
}