import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import StaffChart from './Charts/StaffChart';
import CompanyChart from './Charts/CompanyChart';
import RequestChart from './Charts/RequestsChart';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function AdminStats() {


    // total users
    // donut chart showing how many users are active

    // Total number of companys
    // pie chart showing if companies are paying or trialling

    // total number of requests
    // bar chart breakdown for types of holiday requests
    // bar chart breaktown of state of those requests


    return (
        <Paper sx={{ padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h2">Admin Stats</Typography>
                </Grid>
                <Grid item xs={12}>
                    <StaffChart />
                </Grid>
                <Grid item xs={12}>
                    <CompanyChart />
                </Grid>
                <Grid item xs={12}>
                    <RequestChart />
                </Grid>
            </Grid>
        </Paper>
    );
}