import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { FormContainer, SwitchElement, TextFieldElement } from 'react-hook-form-mui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import { useForm } from 'react-hook-form';
import CreateEventViewModel from './Viewmodels/CreateEventTypeViewModel';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton/ToggleButton';
import Typography from '@mui/material/Typography';
import Popper from '@mui/material/Popper';
import CancelIcon from '@mui/icons-material/Cancel';
import * as Icons from "@mui/icons-material";
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Github from 'react-color/lib/components/github/Github';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
    eventTypeEdit?: CreateEventViewModel;
    eventTypeId?: number;
}

export default function CreateEventType(props: IProps) {

    const handleInitialEntitlementEffect = (eventTypeEdit: CreateEventViewModel | undefined) => {
        if (!eventTypeEdit) {
            return 'negative';
        }
        else {
            if (eventTypeEdit.entitlementEffect === 'Negative' && (eventTypeEdit.toilHours ?? 0) < 0) {
                return 'Negative';
            }
            else {
                return eventTypeEdit.entitlementEffect
            }
        }
    };

    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { open, onClose, eventTypeEdit, eventTypeId } = props;
    const [eventType] = React.useState<CreateEventViewModel>(eventTypeEdit ? eventTypeEdit : new CreateEventViewModel());
    const [entitlementEffect, setEntitlementEffect] = React.useState<string | null>(handleInitialEntitlementEffect(eventTypeEdit));
    const [bradford, setBradford] = React.useState<boolean | null>(eventTypeEdit ? eventTypeEdit.bradfordFactor ?? false : false);
    const [blockPickerColor, setBlockPickerColor] = React.useState(eventTypeEdit ? eventTypeEdit.color : "#3545b4");
    const [blockPickerOpen, setBlockPickerState] = React.useState(false);
    const [iconPickerOpen, setIconPickerState] = React.useState(false);
    const [colourAnchorEl, setColourAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [icon, setIcon] = React.useState<string>(eventTypeEdit ? eventTypeEdit.icon : "AddCircle");
    const [keys, setKeys] = React.useState<string[]>([]);
    const descriptionElementRef = React.useRef<HTMLElement>(null);

    const [searchResults, setSearchResults] = React.useState<string[]>();
    const [search, setSearch] = React.useState('');
    const [nameEntered, setNameEntered] = React.useState<Boolean>(eventTypeEdit ? true : false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (iconPickerOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [iconPickerOpen]);

    React.useEffect(() => {
        const result = Object.entries(Icons)
            .filter(
                ([key, value], i) =>
                    !(key.endsWith("Outlined") || key.endsWith("Rounded") || key.endsWith("TwoTone") || key.endsWith("Sharp")))
            .map(([key]) => key);
        setKeys(result);
    }, []);

    React.useEffect(() => {
        setSearchResults(keys);
    }, [keys]);

    const handleColorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setColourAnchorEl(event.currentTarget);
        setBlockPickerState(!blockPickerOpen);
    };

    const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIconPickerState(!iconPickerOpen);
    };

    const formContext = useForm<CreateEventViewModel>({
        defaultValues: new CreateEventViewModel()
    });

    const handleEntitlementChange = (
        event: React.MouseEvent<HTMLElement>,
        newEntitlement: string | null,
    ) => {
        if (newEntitlement !== null) {
            setEntitlementEffect(newEntitlement);
        }
    };

    const handleBradfordChange = (
        event: React.MouseEvent<HTMLElement>,
        newBradford: boolean | null,
    ) => {
        setBradford(newBradford);
    };

    const handleIconClose = () => {
        setIconPickerState(false);
    }

    const handleIconSelection = (icon: React.SetStateAction<string>) => {
        setIcon(icon);
        setIconPickerState(false);
    }

    const { reset } = formContext;

    React.useEffect(() => {
        reset(eventType);
    }, [eventType]);

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        form.color = blockPickerColor;
        form.entitlementEffect = entitlementEffect;
        if (entitlementEffect == 'None') {
            form.bradfordFactor = bradford;
        } else {
            form.bradfordFactor = null;
        }
        form.icon = icon;

        let url = '';
        if (eventTypeId) url = `Company/AddEventType?id=${eventTypeId}`;
        else url = 'Company/AddEventType';

        crabFetch(url, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', "Successfully created new Event Type");
                    closeDialog(true);
                    setLoading(false);
                }
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
        setLoading(false);
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
        if (event.target.value.length === 0) {
            setSearchResults(keys);
            return;
        }
        searchIcons();
    }

    const searchIcons = () => {
        const IconResults = keys.filter(str => str.toLowerCase().includes(search.toLowerCase().replace(/\s/g, "")));
        setSearchResults(IconResults);
    }

    const cancelSearch = () => {
        setSearch('');
        setSearchResults(keys);
    }

    const keyPress = (event: any) => {
        if (event.keyCode === 13) {
            searchIcons();
        }
    }

    const closeDialog = (refresh: boolean) => {
        onClose(refresh);
        reset(new CreateEventViewModel());
        setEntitlementEffect("negative");
        setIcon("AddCircle");
        setBlockPickerColor("#3545b4");
    }

    const CurrentIcon = React.useMemo(() => {
        return Icons[icon];
    }, [icon]);


    return (
        <Dialog
            open={open}
            onClose={() => closeDialog(false)}
            maxWidth="xs"
            fullWidth
            scroll="paper"
        >
            <FormContainer
                onSuccess={submit}
                formContext={formContext}
            >
                <DialogTitle>{eventTypeId ? "Edit " : "New "} Event Type</DialogTitle>
                <DialogContent >
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="name">Name</InputLabel>
                            <TextFieldElement autoComplete='off' onChange={(e) => setNameEntered(e.target.value.length > 0 ? true : false)} name="name" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="entitlementEffect">How does this event type affect Entitlement or TOIL*?</InputLabel>
                            <ToggleButtonGroup
                                color="primary"
                                exclusive
                                aria-label="Entitlement effect"
                                onChange={handleEntitlementChange}
                                value={entitlementEffect}
                                fullWidth
                                size='small'
                            >
                                <ToggleButton value="negative">Uses Entitlement</ToggleButton>
                                <ToggleButton value="Negative">Uses TOIL</ToggleButton>
                                <ToggleButton value="None">No Effect</ToggleButton>
                                <ToggleButton value="Positive">Accrues TOIL</ToggleButton>
                            </ToggleButtonGroup>
                            <Typography variant="caption" gutterBottom>
                                * Time Off In Lieu
                            </Typography>
                        </Grid>
                        {!(entitlementEffect === "positive" || entitlementEffect === 'none') &&
                            <Grid item xs={12} sx={{ mt: -1, mb: -1 }}>
                                <SwitchElement
                                    label='Staff can request this type of event'
                                    name={'canRequest'}
                                />
                            </Grid>
                        }
                        {entitlementEffect === 'None' &&
                            <Grid item xs={12}>
                                <InputLabel htmlFor="bradfordFactor">Bradford Factor</InputLabel>
                                <ToggleButtonGroup
                                    color="primary"
                                    exclusive
                                    onChange={handleBradfordChange}
                                    value={bradford}
                                    size='small'
                                    fullWidth
                                >
                                    <ToggleButton value={true}>Affects Bradford Factor</ToggleButton>
                                    <ToggleButton value={false}>No Effect</ToggleButton>
                                </ToggleButtonGroup>
                                <Typography variant="caption" display="block" gutterBottom>
                                    This event type will be available for staff to request
                                </Typography>
                            </Grid>
                        }
                        {entitlementEffect === 'Positive' &&
                            <>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="toilHours">Number of hours of TOIL accrued per hour worked</InputLabel>
                                    <TextFieldElement autoComplete='off' name="toilHours" required fullWidth size="small" />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="toilExpiry">Days Until TOIL expiry</InputLabel>
                                    <TextFieldElement autoComplete='off' name="toilExpiry" required fullWidth size="small" />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <InputLabel htmlFor="color">Colour on Calender</InputLabel>
                            <button
                                type='button'
                                style={{
                                    backgroundColor: `${blockPickerColor}`,
                                    width: 33,
                                    height: 33,
                                    border: "4px solid white",
                                    borderRadius: "9px",
                                }}
                                onClick={handleColorClick}
                            />
                            <Popper
                                style={{ zIndex: 10000 }}
                                open={blockPickerOpen}
                                anchorEl={colourAnchorEl}
                                placement='bottom-start'
                            >
                                <ClickAwayListener onClickAway={() => setBlockPickerState(false)}>
                                    <Github
                                        colors={['#FF7A04', '#3545B4', '#A974D3', '#EF3DA1', '#34C3CC', '#34CDDD', '#CC3461', '#B80000', '#ffc0cb', '#800080']}
                                        color={blockPickerColor}

                                        onChange={(color) => {
                                            setBlockPickerColor(color.hex);
                                            setBlockPickerState(!blockPickerOpen);
                                        }} />
                                </ClickAwayListener>
                            </Popper>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="icon">Icon on Calender</InputLabel>
                            <IconButton onClick={handleIconClick} >{CurrentIcon && <CurrentIcon />}</IconButton>
                            <Dialog
                                open={iconPickerOpen}
                                onClose={handleIconClose}
                                PaperProps={{
                                    style: { maxHeight: 600 },
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    '&-ms-overflow-style:': {
                                        display: 'none', // Hide the scrollbar for IE
                                    },
                                    scrollbarWidth: 0
                                }}
                                sx={{
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    '&-ms-overflow-style:': {
                                        display: 'none', // Hide the scrollbar for IE
                                    },
                                    scrollbarWidth: 0
                                }}
                            >
                                <DialogTitle >{"Choose an Icon"}</DialogTitle>
                                <Grid sx={{ p: 1 }} container spacing={1} alignItems='center'>
                                    <Grid item xs={10}>
                                        <TextField
                                            variant="outlined"
                                            type="text"
                                            color="primary"
                                            size="small"
                                            value={search}
                                            placeholder="Search..."
                                            onChange={handleSearch}
                                            onKeyDown={keyPress}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        size="small"
                                                        aria-label="clear search"
                                                        onClick={cancelSearch}
                                                    >
                                                        <CancelIcon fontSize="small" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid xs={2}>
                                        <Button onClick={() => setIconPickerState(false)}>
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                                <DialogContent dividers={true} sx={{
                                    height: 387.5, width: 620, scrollbarWidth: 0, '&::-webkit-scrollbar': {
                                        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                                    },
                                    '&-ms-overflow-style:': {
                                        display: 'none', // Hide the scrollbar for IE
                                    },
                                }}>
                                    {searchResults &&
                                        <Grid container spacing={2} justifyContent="space-evenly">
                                            {searchResults.map(key => {
                                                const Icon = Icons[key];
                                                return (
                                                    <Grid item key={key}>
                                                        <Button onClick={() => handleIconSelection(key)}>
                                                            <Icon fontSize="large" sx={{ color: "#000000" }} />
                                                        </Button>
                                                    </Grid>)
                                            })}
                                        </Grid>
                                    }
                                </DialogContent>
                            </Dialog>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <LoadingButton loading={loading} disabled={!nameEntered} variant="contained" type='submit' fullWidth>Save Event Type</LoadingButton>
                        <Button disabled={loading} variant="outlined" onClick={() => onClose(false)} color="error" fullWidth>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>

        </Dialog>
    );
}