import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import TextField from '@mui/material/TextField/TextField';
import iCalLinkViewModel from './Viewmodels/iCalLinkViewModel';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function ICalLinkDialog(props: IProps) {
    const { open, onClose } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [iCal, setiCal] = React.useState<iCalLinkViewModel>(new iCalLinkViewModel());
    const [copyStatus, setCopyStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch(`Company/GetiCalLink`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            priority: 'low'
        }, ResponseType.JSON,
            (data) => {
                if (data != null) {
                    setiCal(data);  
                }
                setLoading(false);
            }
        );
    }

    const onCopyText = () => {
        setCopyStatus(true);
    };

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>Connect your calendar</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item>
                        <Typography>
                            <p>You can view your colleagues' holidays on your favourite calendar provider, like Outlook or Google Calendar, by linking them with teamplanner.</p>
                        </Typography>
                    </Grid>
                    {!loading &&
                        <Grid item container>
                            {iCal.userCalendar != null &&
                                <Grid item xs={12}>
                                    <Typography>Your Calendar</Typography>
                                    <TextField disabled size='small' variant="outlined" value={iCal.userCalendar} sx={{ width: '80%', mr: 1 }} />
                                    <CopyToClipboard text={iCal.userCalendar} onCopy={onCopyText}>
                                        <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                    </CopyToClipboard>
                                </Grid>
                            }
                            {iCal.memberOfTeamCalendar &&
                                <Grid item xs={12}>
                                    <Typography>Your Team's Calendar</Typography>
                                    <TextField disabled size='small' variant="outlined" value={iCal.memberOfTeamCalendar} sx={{ width: '80%', mr: 1 }} />
                                    <CopyToClipboard text={iCal.memberOfTeamCalendar} onCopy={onCopyText}>
                                        <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                    </CopyToClipboard>
                                </Grid>
                            }
                            {iCal.managerOfTeamCalendar &&
                                <Grid item xs={12} sx={{ mb: 1 }}>
                                    <Typography>Your Team's Calendar</Typography>
                                    <TextField disabled size='small' variant="outlined" value={iCal.managerOfTeamCalendar} sx={{ width: '80%', mr: 1 }} />
                                    <CopyToClipboard text={iCal.managerOfTeamCalendar} onCopy={onCopyText}>
                                        <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                    </CopyToClipboard>
                                </Grid>
                            }
                            <Grid item xs={12} sx={{ mb: 1 }}>
                                <Typography>Company Calendar</Typography>
                                <TextField disabled size='small' variant="outlined" value={iCal.companyCalendar} sx={{ width: '80%', mr: 1 }} />
                                <CopyToClipboard text={iCal.companyCalendar} onCopy={onCopyText}>
                                    <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                </CopyToClipboard>
                            </Grid>
                            {copyStatus && <Typography variant='subtitle1'>Text copied To clipboard!</Typography>}

                        </Grid>
                    }
                    <Grid>
                        <Accordion disableGutters sx={{ mb: 1 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreRounded />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                Google Calendar
                            </AccordionSummary>
                            <AccordionDetails>
                                <ol>
                                    <li>
                                        <p><b><a href="https://calendar.google.com" target="_blank" rel="noreferrer">Go to Google Calendar and sign in.</a></b></p>
                                    </li>
                                    <li>
                                        <p><b>Navigate to the settings page and then the 'From URL' section.</b></p>
                                        <img style={{ maxWidth: "250px" }} alt="Google Calendar Settings Location" src="/images/help/GoogleCal1.png"></img>
                                        <p></p>
                                        <img style={{ maxWidth: "250px" }} alt="Google Calendar Settings From URL location" src="/images/help/GoogleCal2.png"></img>
                                    </li>
                                    <li>
                                        <p><b>Copy one of these links:</b></p>
                                        {!loading &&
                                            <Grid item container>
                                                {iCal.userCalendar != null &&
                                                    <Grid item xs={12}>
                                                        <Typography>Your Calendar</Typography>
                                                        <TextField disabled size='small' variant="outlined" value={iCal.userCalendar} sx={{ width: '70%', mr: 1 }} />
                                                        <CopyToClipboard text={iCal.userCalendar} onCopy={onCopyText}>
                                                            <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                }
                                                {iCal.memberOfTeamCalendar &&
                                                    <Grid item xs={12}>
                                                        <Typography>Your Team's Calendar</Typography>
                                                        <TextField disabled size='small' variant="outlined" value={iCal.memberOfTeamCalendar} sx={{ width: '70%', mr: 1 }} />
                                                        <CopyToClipboard text={iCal.memberOfTeamCalendar} onCopy={onCopyText}>
                                                            <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                }
                                                {iCal.managerOfTeamCalendar &&
                                                    <Grid item xs={12} sx={{ mb: 1 }}>
                                                        <Typography>Your Team's Calendar</Typography>
                                                        <TextField disabled size='small' variant="outlined" value={iCal.managerOfTeamCalendar} sx={{ width: '70%', mr: 1 }} />
                                                        <CopyToClipboard text={iCal.managerOfTeamCalendar} onCopy={onCopyText}>
                                                            <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} sx={{ mb: 1 }}>
                                                    <Typography>Company Calendar</Typography>
                                                    <TextField disabled size='small' variant="outlined" value={iCal.companyCalendar} sx={{ width: '70%', mr: 1 }} />
                                                    <CopyToClipboard text={iCal.companyCalendar} onCopy={onCopyText}>
                                                        <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                    </CopyToClipboard>
                                                </Grid>
                                                {copyStatus && <Typography variant='subtitle1'>Text copied To clipboard!</Typography>}
                                            </Grid>
                                        }
                                    </li>
                                    <li>
                                        <p><b>Paste it into the 'From URL' box in the Google Calendar settings and click 'Add calender'.</b></p>

                                        <img style={{ maxWidth: "250px" }} alt="Google Calendar Settings From URL box location" src="/images/help/GoogleCal3.png"></img>

                                        <p>Google Calendar will now fetch the events from teamplanner and they'll be available to see on your calendar. </p>

                                        <p><b>Please note that once synced, Google Calendar will only fetch new events every 8 hours or so.</b></p>
                                    </li>
                                </ol>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disableGutters sx={{ mb: 1 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreRounded />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                Apple Calendar
                            </AccordionSummary>
                            <AccordionDetails>
                                <ol>
                                    <li>
                                        <p><b>Copy one of these links:</b></p>
                                        {!loading &&
                                            <Grid item container>
                                                {iCal.userCalendar != null &&
                                                    <Grid item xs={12}>
                                                        <Typography>Your Calendar</Typography>
                                                        <TextField disabled size='small' variant="outlined" value={iCal.userCalendar} sx={{ width: '70%', mr: 1 }} />
                                                        <CopyToClipboard text={iCal.userCalendar} onCopy={onCopyText}>
                                                            <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                }
                                                {iCal.memberOfTeamCalendar &&
                                                    <Grid item xs={12}>
                                                        <Typography>Your Team's Calendar</Typography>
                                                        <TextField disabled size='small' variant="outlined" value={iCal.memberOfTeamCalendar} sx={{ width: '70%', mr: 1 }} />
                                                        <CopyToClipboard text={iCal.memberOfTeamCalendar} onCopy={onCopyText}>
                                                            <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                }
                                                {iCal.managerOfTeamCalendar &&
                                                    <Grid item xs={12} sx={{ mb: 1 }}>
                                                        <Typography>Your Team's Calendar</Typography>
                                                        <TextField disabled size='small' variant="outlined" value={iCal.managerOfTeamCalendar} sx={{ width: '70%', mr: 1 }} />
                                                        <CopyToClipboard text={iCal.managerOfTeamCalendar} onCopy={onCopyText}>
                                                            <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} sx={{ mb: 1 }}>
                                                    <Typography>Company Calendar</Typography>
                                                    <TextField disabled size='small' variant="outlined" value={iCal.companyCalendar} sx={{ width: '70%', mr: 1 }} />
                                                    <CopyToClipboard text={iCal.companyCalendar} onCopy={onCopyText}>
                                                        <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                    </CopyToClipboard>
                                                </Grid>
                                                {copyStatus && <Typography variant='subtitle1'>Text copied To clipboard!</Typography>}
                                            </Grid>
                                        }
                                    </li>
                                    <li>
                                        <p><b>Open your Apple Calendar iOS app.</b></p>
                                    </li>
                                    <li>
                                        <p><b>Go to Settings &gt; Calendar &gt; Accounts &gt; Add account.</b></p>
                                    </li>
                                    <li>
                                        <p><b>Choose Other &gt; Add Subscribed Calendar.</b></p>
                                    </li>
                                    <li>
                                        <p><b>Paste the server address and select Next.</b></p>
                                    </li>
                                    <li>
                                        <p><b>Enter any other required information and select Save.</b></p>
                                    </li>
                                    <li>
                                        <p>Apple Calendar will now show events on your calendar. </p>

                                        <p><b>Please note that once synced, Outlook Calendar will only fetch new events every 8 hours or so.</b></p>
                                    </li>
                                </ol>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disableGutters sx={{ mb: 1 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreRounded />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                Outlook 365
                            </AccordionSummary>
                            <AccordionDetails>
                                <ol>
                                    <li>
                                        <p><b><a href="https://outlook.office.com/calendar/" target="_blank" rel="noreferrer">Go to Outlook Calendar and sign in.</a></b></p>
                                    </li>
                                    <li>
                                        <p><b>Click the 'Add calendar' button and navigate to the 'Subscribe from Web' area.</b></p>
                                        <img style={{ maxWidth: "250px" }} alt="Outlook Calendar Settings Location" src="/images/help/OutlookCal1.png"></img>
                                        <p></p>
                                        <img style={{ maxWidth: "250px" }} alt="Outlook Calendar Settings From URL location" src="/images/help/OutlookCal2.png"></img>
                                    </li>
                                    <li>
                                        <p><b>Copy one of these links:</b></p>
                                        {!loading &&
                                            <Grid item container>
                                                {iCal.userCalendar != null &&
                                                    <Grid item xs={12}>
                                                        <Typography>Your Calendar</Typography>
                                                        <TextField disabled size='small' variant="outlined" value={iCal.userCalendar} sx={{ width: '70%', mr: 1 }} />
                                                        <CopyToClipboard text={iCal.userCalendar} onCopy={onCopyText}>
                                                            <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                }
                                                {iCal.memberOfTeamCalendar &&
                                                    <Grid item xs={12}>
                                                        <Typography>Your Team's Calendar</Typography>
                                                        <TextField disabled size='small' variant="outlined" value={iCal.memberOfTeamCalendar} sx={{ width: '70%', mr: 1 }} />
                                                        <CopyToClipboard text={iCal.memberOfTeamCalendar} onCopy={onCopyText}>
                                                            <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                }
                                                {iCal.managerOfTeamCalendar &&
                                                    <Grid item xs={12} sx={{ mb: 1 }}>
                                                        <Typography>Your Team's Calendar</Typography>
                                                        <TextField disabled size='small' variant="outlined" value={iCal.managerOfTeamCalendar} sx={{ width: '70%', mr: 1 }} />
                                                        <CopyToClipboard text={iCal.managerOfTeamCalendar} onCopy={onCopyText}>
                                                            <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                        </CopyToClipboard>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} sx={{ mb: 1 }}>
                                                    <Typography>Company Calendar</Typography>
                                                    <TextField disabled size='small' variant="outlined" value={iCal.companyCalendar} sx={{ width: '70%', mr: 1 }} />
                                                    <CopyToClipboard text={iCal.companyCalendar} onCopy={onCopyText}>
                                                        <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                    </CopyToClipboard>
                                                </Grid>
                                                {copyStatus && <Typography variant='subtitle1'>Text copied To clipboard!</Typography>}
                                            </Grid>
                                        }
                                    </li>
                                    <li>
                                        <p><b>Paste the copied URL into the box in the 'Subscribe from web' settings, give it a name and configure the settings and then click 'Import'.</b></p>

                                        <img style={{ maxWidth: "250px" }} alt="Outlook Calendar Settings URL box location" src="/images/help/OutlookCal3.png"></img>

                                        <p>Outlook Calendar will now fetch the events from teamplanner and they'll be available to see on your calendar. </p>

                                        <p><b>Please note that once synced, Outlook Calendar will only fetch new events every 8 hours or so.</b></p>
                                    </li>
                                </ol>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion disableGutters sx={{ mb: 1 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreRounded />}
                                aria-controls="panel3-content"
                                id="panel3-header"
                            >
                                Other calendar
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>
                                    Most calendar providers will allow you to add or subscribe to an external calendar from a URL or link.
                                </p>
                                <p>When it asks you for the URL or link, simply copy and paste one of the links below into it.</p>
                                {!loading &&
                                    <Grid item container>
                                        {iCal.userCalendar != null &&
                                            <Grid item xs={12}>
                                                <Typography>Your Calendar</Typography>
                                                <TextField disabled size='small' variant="outlined" value={iCal.userCalendar} sx={{ width: '70%', mr: 1 }} />
                                                <CopyToClipboard text={iCal.userCalendar} onCopy={onCopyText}>
                                                    <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                </CopyToClipboard>
                                            </Grid>
                                        }
                                        {iCal.memberOfTeamCalendar &&
                                            <Grid item xs={12}>
                                                <Typography>Your Team's Calendar</Typography>
                                                <TextField disabled size='small' variant="outlined" value={iCal.memberOfTeamCalendar} sx={{ width: '70%', mr: 1 }} />
                                                <CopyToClipboard text={iCal.memberOfTeamCalendar} onCopy={onCopyText}>
                                                    <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                </CopyToClipboard>
                                            </Grid>
                                        }
                                        {iCal.managerOfTeamCalendar &&
                                            <Grid item xs={12} sx={{ mb: 1 }}>
                                                <Typography>Your Team's Calendar</Typography>
                                                <TextField disabled size='small' variant="outlined" value={iCal.managerOfTeamCalendar} sx={{ width: '70%', mr: 1 }} />
                                                <CopyToClipboard text={iCal.managerOfTeamCalendar} onCopy={onCopyText}>
                                                    <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                                </CopyToClipboard>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                            <Typography>Company Calendar</Typography>
                                            <TextField disabled size='small' variant="outlined" value={iCal.companyCalendar} sx={{ width: '70%', mr: 1 }} />
                                            <CopyToClipboard text={iCal.companyCalendar} onCopy={onCopyText}>
                                                <Button variant="outlined" disabled={loading}>Copy to Clipboard</Button>
                                            </CopyToClipboard>
                                        </Grid>
                                        {copyStatus && <Typography variant='subtitle1'>Text copied To clipboard!</Typography>}
                                    </Grid>
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={() => onClose(false)} variant="contained">Done</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}