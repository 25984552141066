import * as React from 'react';
import Grid from '@mui/material/Grid';
import AdminManagementTable from './AdminManagementTable';

export default function AdminDashboard() {
    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <AdminManagementTable />
            </Grid>
        </Grid>
    );
}