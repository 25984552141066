import * as React from 'react';
import styled from '@emotion/styled';
import Table, { TableProps } from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';

const ScrollTable = styled("div")(() => ({
    display: "block",
    overflow: "auto"
}));

interface ResponsiveProps extends TableProps {
    maxHeight?: number;
}

export default function ResponsiveTable(props: ResponsiveProps) {
    const propStyle = props.maxHeight ? { height: props.maxHeight } : {};

    return (
        <ScrollTable style={propStyle}>
            <Table {...props} />
        </ScrollTable>
    );
}

export const SmallTableCell = styled(TableCell)`
        root: {
            padding: 8
        }`;