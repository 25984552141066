import * as React from 'react';
import Grid from '@mui/material/Grid';
import CompanyDetails from './CompanyDetails';

export default function CompanySettings() {

    return (
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                <CompanyDetails />
            </Grid>
        </Grid>
    );
}