import dayjs, { Dayjs } from "dayjs";

export default class CompanyEventViewModel {
    public id: number | null = null;
    public name: string = "";
    public eventType: number = 0;
    public start: Dayjs = dayjs();
    public startEndTime: Dayjs = dayjs();
    public fullStartDay: boolean = true;
    public end: Dayjs = dayjs();
    public endStartTime: Dayjs = dayjs();
    public fullEndDay: boolean = true;
}