import * as React from 'react';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from './TokenRefreshContext';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from './AlertContext';

type ContextType = {
    currCompany: number | null;
    changeCompany: (company: number | null) => void;
}

type AdminProviderProps = { children: React.ReactNode };

export const AdminContext = React.createContext<ContextType>({
    currCompany: null,
    changeCompany: () => {
        throw Error('Cannot use a context without a provider');
    }
});

export default function AdminProvider({ children }: AdminProviderProps) {
    const navigate = useNavigate();
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [currCompany, setCurrCompany] = React.useState<null | number>(null);

    React.useEffect(() => {
        getCurrentCompany();
    }, []);

    const getCurrentCompany = async () => {
        const user = await authService.getUser();
        if (!user || user.role !== 'Admin') return;

        const token = await authService.getAccessToken();

        crabFetch(`User/GetCurrentlyViewedCompany`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.Text,
            (data: any) => {
                data = data.length > 0 ? data : null;
                setCurrCompany(data);
            }
        )
    }

    const changeCompany = async (company: null | number) => {
        const user = await authService.getUser();
        if (!user || user.role !== 'Admin') return;

        const token = await authService.getAccessToken();
        crabFetch(`User/ChangeViewCompany?${company ? `company=${company}` : ''}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    if (company !== null) {
                        show('success', 'Successfully started viewing company');
                    }
                    setCurrCompany(company);
                    navigate('/');
                }
            },
            (error: any) => {
                show('error', 'An error occurred');
            }
        )
    }

    return (
        <AdminContext.Provider value={{ currCompany: currCompany, changeCompany: changeCompany }}>
            {children}
        </AdminContext.Provider>
    );
}