import dayjs, { Dayjs } from "dayjs";

export default class MemberViewModel {
    public firstName: string = '';
    public lastName: string = '';
    public email: string = '';
    public hasEmailNotifications: boolean = false;
    public startDate: Dayjs | null = dayjs();
    public leaveDate: Date | null = null;
    public role: string = '';
    public roleId: string = '';
    public teamId: string | null = null;
    public teamName: string | null = null;
}