import * as React from 'react';
import Grid from '@mui/material/Grid';
import CompanyHolidaysTable from './CompanyHolidayTable';
import BankHolidayTable from './BankHolidayTable';

export default function ManageHolidays() {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CompanyHolidaysTable />
            </Grid>
            <Grid item xs={12}>
                <BankHolidayTable />
            </Grid>
        </Grid>
    );
}