import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import authService from '../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ResponseType, TokenRefreshContext } from './TokenRefreshContext';
import { AlertContext } from './AlertContext';
import LoadingButton from '@mui/lab/LoadingButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SubscriptionStatusViewModel from '../Billing/Viewmodels/SubscriptionStatusViewModel';
import StaffTable from '../Billing/StaffBillingTable';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { AdminContext } from './AdminContext';

type ContextType = {

};

type BillingProviderProps = { children: React.ReactNode };

export const BillingContext = React.createContext<ContextType>({});

export default function BillingProvider({ children }: BillingProviderProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const { changeCompany } = React.useContext(AdminContext);
    const navigate = useNavigate();
    const [openPanel, setOpenPanel] = React.useState(false);
    const [showManageStaff, setshowManageStaff] = React.useState(false);
    const [canClose, setCanClose] = React.useState(false);
    const authSubscription = authService.subscribe(() => getData());
    const [reasonForShowing, setReasonForShowing] = React.useState('');
    const [stripeLoading, setStripeLoading] = React.useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState(new SubscriptionStatusViewModel());

    React.useEffect(() => {
        getData();

        return () => {
            authService.unsubscribe(authSubscription);
        }
    }, []);

    React.useEffect(() => {

        if (subscriptionStatus.companyId === 0) {
            setOpenPanel(false);
            return;
        }

        if (subscriptionStatus.status !== "active" && subscriptionStatus.status !== "trialing") {
            setOpenPanel(true);
            setReasonForShowing("expired");
        }
        else if (subscriptionStatus.usedSeats > subscriptionStatus.seatsInSubscription && subscriptionStatus.status !== "trialing") {
            setOpenPanel(true);
            setReasonForShowing("tooManyStaff");
        }
        else {
            if (!showManageStaff) {
                setOpenPanel(false);
            }
            setCanClose(true);
        }
    }, [subscriptionStatus]);

    const manageStaff = () => {
        setshowManageStaff(true);
    }

    const getData = async () => {
        const authenticated = await authService.isAuthenticated();
        if (authenticated) {
            const token = await authService.getAccessToken();
            crabFetch(`Billing/GetSubscriptionStatus`, {
                method: 'GET',
                headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
            }, ResponseType.JSON,
                (data: any) => {
                    if (data.length > 0)
                        show('error', data);

                    setSubscriptionStatus(data);
                }
            );
        }
    }

    const closePanel = async (event: any, reason: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
        else {
            const user = await authService.getUser();
            setOpenPanel(false);

            if (user && user.role === 'Admin') {
                changeCompany(null);
            }
            else {
                navigate(`${ApplicationPaths.LogOut}`, {
                    state: {
                        local: true
                    }
                });
            }
        }
    }

    const finishArchiving = () => {
        setOpenPanel(false);
    }

    const goToBillingPortal = async () => {
        const token = await authService.getAccessToken();
        setStripeLoading(true);

        crabFetch('Billing/GoToBillingPortal', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'text/plain; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'text/plain; charset=utf-8' }
        }, ResponseType.Text,
            (data: string) => {
                if (data.includes('http')) {
                    window.location.href = data;
                } else {
                    show('error', data);
                }
            }
        );
    }


    return (
        <BillingContext.Provider value={{}}>
            <Dialog
                open={openPanel}
                onClose={closePanel}
                maxWidth="md"
                fullWidth
            >
                {reasonForShowing === "expired" &&
                    <>
                        <DialogTitle>Free Trial Expired</DialogTitle>
                        <DialogContent>
                            <Typography> Your free trial has expired. Please upgrade to a paid subscription.</Typography>
                            <DialogActions>
                                <LoadingButton endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Upgrade Now</LoadingButton>
                                <Button color="error" onClick={() => closePanel(null, '')}>Decline</Button>
                            </DialogActions>
                        </DialogContent>
                    </>
                }
                {!showManageStaff && reasonForShowing === "tooManyStaff" &&
                    <>
                        <DialogTitle>More licences required</DialogTitle>
                        <DialogContent>
                            <Alert variant="outlined" severity="warning">You have reached your licence limit. You're using <b>{subscriptionStatus.usedSeats} out of {subscriptionStatus.seatsInSubscription}</b> paid seats.
                                <p>
                                    <b>Please purchase at least {subscriptionStatus.usedSeats - subscriptionStatus.seatsInSubscription} more seat(s)</b> or <b>remove {subscriptionStatus.usedSeats - subscriptionStatus.seatsInSubscription} staff user(s).</b></p>
                            </Alert>
                            <DialogActions>
                                <LoadingButton endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Add More Seats</LoadingButton>
                                <Button variant="contained" color="primary" onClick={() => manageStaff()}>Manage Staff</Button>
                            </DialogActions>
                        </DialogContent>
                    </>
                }
                {showManageStaff && reasonForShowing === "tooManyStaff" &&
                    <>
                        <DialogTitle>More licences required</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {(subscriptionStatus.usedSeats - subscriptionStatus.seatsInSubscription > 0) &&
                                        <Alert variant="outlined" severity="warning">Please remove <b>{subscriptionStatus.usedSeats - subscriptionStatus.seatsInSubscription}</b> more staff user(s)</Alert>
                                    }
                                    {(subscriptionStatus.usedSeats - subscriptionStatus.seatsInSubscription <= 0) &&
                                        <Alert variant="outlined" severity="success"> No more staff users need to be removed. 😊</Alert>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography> Please remove any unused staff members. If you are unable to remove any, you will have to add more seats to your subscription.</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <StaffTable refresh={getData} />
                                </Grid>
                            </Grid>
                            <DialogActions>
                                <LoadingButton endIcon={<OpenInNewIcon />} loading={stripeLoading} variant="contained" onClick={goToBillingPortal}>Add More Seats</LoadingButton>
                                <Button variant="contained" color="primary" disabled={!canClose} onClick={() => finishArchiving()}>Finish</Button>
                            </DialogActions>
                        </DialogContent>
                    </>
                }
            </Dialog>
            {children}
        </BillingContext.Provider>
    );
}