import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField/TextField';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function InvoiceDialog(props: IProps) {
    const { open, onClose } = props;
    const [copyStatus, setCopyStatus] = React.useState(false);

    const onCopyText = () => {
        setCopyStatus(true);
    };

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Invoice Payments</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>
                            To pay your invoice please contact us at:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField variant="outlined" value={'accounts@theteamplanner.com'} fullWidth />
                    </Grid>
                    <Grid item>
                        <CopyToClipboard text={'accounts@theteamplanner.com'} onCopy={onCopyText}>
                            <Button variant="outlined">Copy to Clipboard</Button>
                        </CopyToClipboard>
                    </Grid>
                    <Grid item>
                        {copyStatus && <Typography variant='subtitle1'>Text copied To clipboard!</Typography>}
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={() => onClose(false)} variant="contained">Done</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}