import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import HolidaysTableViewModel from './Viewmodels/HolidaysTableViewModel';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import CompanyHolidaysTableRow from './CompanyHolidaysTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import CreateCompanyEvent from './CreateCompanyEvent';
import ManageCompanyEvent from './ManageCompanyEvent';
import WarningDialog from '../Utilities/WarningDialog';
import { AlertContext } from '../Contexts/AlertContext';


//import WarningDialog from '../Utilities/WarningDialog';
//import { SelectElement } from 'react-hook-form-mui';

const headCells: HeadCell<HolidaysTableViewModel>[] = [
    { id: 'name', property: 'Name', align: "left", disablePadding: false, label: 'Name' },
    { id: 'date', property: 'Date', align: "left", disablePadding: false, label: 'Date' }
];

export default function CompanyHolidaysTable() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof HolidaysTableViewModel>('date');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [results, setResults] = React.useState<HolidaysTableViewModel[]>([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [openAdd, setOpenAdd] = React.useState(false);
    const [edit, setEdit] = React.useState<number | null>(null);
    //const [archiveHolidayId, setArchiveHolidayEventId] = React.useState<number | null>(null);
    const [removeHolidayiId, setRemoveHolidayId] = React.useState<number | null>(null);
    const { show } = React.useContext(AlertContext);


    const toggleOpenAdd = () => setOpenAdd((prev) => !prev);


    const closeAdd = (refresh: boolean) => {
        if (refresh) getData();

        setOpenAdd(false);
        setEdit(null);
    }

    React.useEffect(() => {
        getData();
    }, [page, rowsPerPage, order, orderBy]);

    React.useEffect(() => {

    }, [results]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Event/GetCompanyHolidaysTable?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    const remove = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        crabFetch(`Event/RemoveCompanyEvent?Id=${removeHolidayiId}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully removed event`);
                    setRemoveHolidayId(null);
                    getData();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof HolidaysTableViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;



    return (
        <>
            <Grid item sx={{ pb: 1 }}>
                <Typography variant="h1">Company Events</Typography>
            </Grid>
            <Paper>
                <Grid container spacing={2}>
                    <Grid container item xs={12} alignItems="center" justifyContent="flex-end">
                        <Grid item>
                            <Button aria-label={"Add Company Holiday"} onClick={toggleOpenAdd} variant="contained" color="primary">Add New Company Event</Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <ResponsiveTable
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="holiday table"
                            >
                                <SortableTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    headCells={headCells}
                                    lastCells={<TableCell />}
                                />
                                <TableBody>
                                    {(!loading && results) && results.map((row: HolidaysTableViewModel) => {
                                        return (<CompanyHolidaysTableRow key={row.id} row={row} edit={setEdit} isBankHoliday={false} remove={setRemoveHolidayId} />);
                                    })
                                    }
                                    {count <= 0 && !loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                        </TableRow>
                                    }
                                    {count > 0 && emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: 53 * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} />
                                        </TableRow>
                                    )}
                                    {loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * rowsPerPage,
                                            }}
                                        >
                                            <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <CreateCompanyEvent open={openAdd} onClose={closeAdd} />
                {edit && <ManageCompanyEvent open={edit !== null && edit > 0} onClose={closeAdd} eventId={edit!} publicHol={false} />}
                <WarningDialog open={removeHolidayiId !== null} close={() => setRemoveHolidayId(null)} buttonText='Remove' text='This will remove the holiday for all employees' okClick={remove} />

            </Paper>
        </>
    );
}