import * as React from 'react';
import SortableTableHead, { HeadCell, Order } from '../Utilities/SortableTableHead';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import TablePagination from '@mui/material/TablePagination';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import StaffTableViewModel from './Viewmodels/StaffTableViewModel';
import StaffTableRow from './StaffBillingTableRow';
import MenuItem from '@mui/material/MenuItem';
import DropdownViewModel from '../Utilities/ViewModels/DropdownViewModel';
import debounce from 'lodash/debounce';
import SearchIcon from '@mui/icons-material/Search';

const headCells: HeadCell<StaffTableViewModel>[] = [
    { id: 'name', property: 'Name', align: "left", disablePadding: false, label: 'Name' },
    { id: 'memberOf', property: 'MemberOf', align: "left", disablePadding: false, label: 'Member Of' },
    { id: 'status', property: 'Status', align: "left", disablePadding: false, label: 'Status' },
];

interface IProps {
    refresh: () => void;
}
export default function StaffTable(props: IProps) {
    const { refresh } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof StaffTableViewModel>('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [results, setResults] = React.useState<StaffTableViewModel[]>([]);
    const [search, setSearch] = React.useState('');
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [teams, setTeams] = React.useState<DropdownViewModel[]>([]);
    const [team, setTeam] = React.useState(0);
    const [showAdminControls, setShowAdminControls] = React.useState(false);

    React.useEffect(() => {
        getData();
    }, [page, order, orderBy, rowsPerPage, search]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        const user = await authService.getUser();
        setShowAdminControls(user.role !== "Staff Member");
        const orderByProp = headCells.find(f => f.id === orderBy)!.property;

        await crabFetch(`Staff/GetStaffMembers?orderBy=${orderByProp}&order=${order}&page=${page}&pageRows=${rowsPerPage}&search=${search}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
                refresh();
            },
            () => {
                setLoading(false);
            }
        );

        crabFetch(`Team/GetTeamDropdown`, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: DropdownViewModel[]) => {
                data.unshift({ id: 0, label: 'All Teams' });
                setTeams(data);
            }
        );
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof StaffTableViewModel) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value);
    }

    const debouncedhandleSearch =
        React.useCallback!(
            debounce(handleSearch, 600),
            []
        );

    const onTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(e.target.valueAsNumber) ?
            e.target.value :
            e.target.valueAsNumber;

        setTeam(value as number);
    }

    const emptyRows = page > 0 ? Math.max(0, rowsPerPage - results.length) : 0;

    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={12} md={8}>
                    <Grid container spacing={2}>
                        {showAdminControls &&
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    autoComplete='off'
                                    fullWidth
                                    select
                                    value={team}
                                    onChange={onTeamChange}
                                    size={'small'}
                                >
                                    {teams.map((item) => (
                                        <MenuItem key={'team' + item.id} value={item.id}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        }
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete='off'
                                color="primary"
                                size="small"
                                variant="outlined"
                                onChange={debouncedhandleSearch}
                                placeholder="Search..."
                                InputProps={{
                                    endAdornment: <SearchIcon sx={{ color: '#B5ADC4' }} />,
                                }}
                            />
                        </Grid>
                     
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer>
                        <ResponsiveTable
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="user table"
                        >
                            <SortableTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                headCells={headCells}
                                lastCells={<TableCell />}
                            />
                            <TableBody>
                                {(!loading && results) && results.map((row: StaffTableViewModel) => {
                                    return (<StaffTableRow key={row.id} row={row} refresh={getData} />);
                                })
                                }
                                {count <= 0 && !loading &&
                                    <TableRow
                                        sx={{
                                            height: 53 * rowsPerPage,
                                        }}
                                    >
                                        <TableCell colSpan={headCells.length + 1} align="center">No Results Found</TableCell>
                                    </TableRow>
                                }
                                {count > 0 && emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 53 * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={headCells.length + 1} />
                                    </TableRow>
                                )}
                                {loading &&
                                    <TableRow
                                        sx={{
                                            height: 53 * rowsPerPage,
                                        }}
                                    >
                                        <TableCell colSpan={headCells.length + 1} align="center"><CircularProgress color="primary" /></TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </ResponsiveTable>
                    </TableContainer>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    );
}
