export enum WeekDayOptions {
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
};

export enum Months {
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
}

export enum DayTypes {
    'All Day',
    'Morning',
    'Afternoon'
}

/**
* Number of days for given month (offset by 1 to match c# side) 
* Eg. January = 1
*/
export const monthDayValues = [
    { month: 1, days: 31 },
    { month: 2, days: 28 },
    { month: 3, days: 31 },
    { month: 4, days: 30 },
    { month: 5, days: 31 },
    { month: 6, days: 30 },
    { month: 7, days: 31 },
    { month: 8, days: 31 },
    { month: 9, days: 30 },
    { month: 10, days: 31 },
    { month: 11, days: 30 },
    { month: 12, days: 31 },
];

export const partialDayOptions = [
    { id: 0, label: 'All Day' },
    { id: 1, label: 'Morning' },
    { id: 2, label: 'Afternoon' }
]