import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean) => void;
}

export default function CreateTeam(props: IProps) {
    const { open, onClose } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);

    const formContext = useForm({
        defaultValues: { name: '', attendanceQuota: 0 }
    });
    const { reset } = formContext;


    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        setLoading(true);

        crabFetch(`Team/CreateTeam`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully created team`);
                    closeDialog(true);
                    setLoading(false);
                }
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    const closeDialog = (refresh: boolean) => {
        reset();
        onClose(refresh);
    }

    return (
        <Dialog
            open={open}
            onClose={() => closeDialog(false)}
            fullWidth
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>Create Team</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel htmlFor="name" shrink>Name</InputLabel>
                            <TextFieldElement autoComplete='off' name="name" required fullWidth size="small" />
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <LoadingButton loading={loading} variant="contained" type="submit" fullWidth>Create</LoadingButton>
                        <Button disabled={loading} variant="outlined" onClick={() => closeDialog(false)} color="error" fullWidth>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}