import ShiftWeekViewModel from "./ShiftWeekViewModel";

export default class ShiftPatternViewModel {
    public id: number | null = null;
    public name: string = '';
    public default: boolean = false;
    public startWeek: number | null = 0;
    public weeks: ShiftWeekViewModel[] = [];
    public workingWeekStart: number = 0;
    public viewingUserCanEdit: boolean = false;
}