import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import UserViewmodel from './Viewmodels/UserViewModel';
import { TokenRefreshContext, ResponseType } from '../../Contexts/TokenRefreshContext';
import { AlertContext } from '../../Contexts/AlertContext';
import authService from '../../api-authorization/AuthorizeService';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { ArrowDropDownIcon } from '@mui/x-date-pickers/icons';

interface IProps {
    row: UserViewmodel;
    refresh: () => void;
}

export default function UserManagementTableRow(props: IProps) {
    const { row, refresh } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const toggleArchive = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const method = row.archived ? 'UnArchiveUser' : 'ArchiveUser';

        crabFetch(`User/${method}?id=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${row.archived ? 're-enabled' : 'archived'} User`);
                    refresh();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const reset = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        crabFetch(`User/ResetPassword?userId=${row.id}`, {
            method: 'POST',
            headers: !token ? { } : { 'Authorization': `Bearer ${token}` },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully sent reset Email`);
                    refresh();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const confirm = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);
        crabFetch(`User/ResendEmailConfirmation?userId=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully sent confimation Email`);
                    refresh();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        setOpen(false);
    };


    return (
        <TableRow>
            <TableCell>{row.firstName}</TableCell>
            <TableCell>{row.lastName}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.role}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell>{row.company}</TableCell>
            <TableCell align="right">
                <>
                    <ButtonGroup variant="contained" ref={anchorRef} aria-label="User actions dropdown">
                        <Button
                            size="small"
                            aria-label="user Actions"
                            onClick={handleToggle}
                        >
                            Options<ArrowDropDownIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu" autoFocusItem>
                                            <MenuItem
                                                onClick={reset}
                                                aria-label="Reset Password"
                                                disabled={loading}
                                            >
                                                Reset Password
                                            </MenuItem>
                                            <MenuItem
                                                onClick={toggleArchive}
                                                aria-label={row.archived ? "Unarchive" : "Archive User"}
                                                disabled={loading}
                                            >
                                                {row.archived ? "Activate" : "Archive"}
                                            </MenuItem>
                                            {row.status === "Invited" &&
                                                <MenuItem
                                                    onClick={() => confirm()}
                                                    aria-label={"Re-send Confirmation Email"}
                                                    disabled={loading}
                                                >
                                                    Re-send Confirmation Email
                                                </MenuItem>
                                            }
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </>
            </TableCell>
        </TableRow>
    );
}