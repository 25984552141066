import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import authService from '../api-authorization/AuthorizeService';
import { FormContainer, DatePickerElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { useForm } from 'react-hook-form';
import 'moment/locale/en-gb';
import SetLeaveDateViewModel from './Viewmodels/SetLeaveDateViewModel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingButton from '@mui/lab/LoadingButton';
import moment from 'moment';

interface IProps {
    open: boolean;
    onClose: (refresh: boolean, success: boolean) => void;
    userId: string[]
}

export default function SetLeaveDate(props: IProps) {
    const { open, onClose, userId } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);

    const [startDate, setStartDate] = React.useState<Date | null>(null);

    const [loading, setLoading] = React.useState(false);

    const getData = async () => {
        const token = await authService.getAccessToken();

        crabFetch('Staff/GetStaffStartDate?id=' + userId, {
            method: 'GET',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' }
        }, ResponseType.JSON,
            (data: any) => {
                setStartDate(data);
            }
        );
    }

    React.useEffect(() => {
        getData();
    }, [userId]);

    const formContext = useForm<SetLeaveDateViewModel>({
        defaultValues: {
            date: null,
            userId: userId
        }
    });

    const { reset } = formContext;

    React.useEffect(() => {
        reset({ date: null, userId: userId });
    }, [userId]);

    const submit = async (form: any) => {
        const token = await authService.getAccessToken();
        form.userId = userId;
        setLoading(true);

        if (form.date) {
            const offset = form.date.utcOffset();
            form.date = form.date.add(offset, 'm');
        }

        crabFetch(`Staff/AddLeaveDate`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully set leave Date`);
                    closeDialog(true);
                    setLoading(false);
                }
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    const closeDialog = (refresh: boolean) => {
        reset();
        onClose(refresh, true);
    }

    return (
        <Dialog
            open={open}
            onClose={() => closeDialog(false)}
            fullWidth
            maxWidth="xs"
        >
            <FormContainer
                formContext={formContext}
                onSuccess={submit}
            >
                <DialogTitle>
                    <Grid container>
                        <Grid item>Set Leave Date</Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-gb'}>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="date" shrink>Date</InputLabel>
                                <DatePickerElement minDate={moment(startDate)} name="date" textReadOnly required inputProps={{ fullWidth: true, size: "small" }} />
                            </Grid>
                        </LocalizationProvider>
                    </Grid>
                    <DialogActions>
                        <LoadingButton loading={loading} variant="contained" type="submit" fullWidth>Confirm</LoadingButton>
                        <Button disabled={loading} variant="outlined" onClick={() => closeDialog(false)} color="error" fullWidth>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </FormContainer>
        </Dialog>
    );
}