import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import HolidayAllowanceViewModel from './Viewmodels/HolidayAllowanceViewModel';
import CalculationExplainerDialog from './CalculationExplainerDialog';

interface IProps {
    holidayAllowance: HolidayAllowanceViewModel;
    loading: boolean;
}

export default function HolidayAllowance(props: IProps) {
    const { loading, holidayAllowance } = props;
    const [open, setOpen] = React.useState(false);

    const handleDialogClose = (refresh: boolean) => {
        setOpen(false);
    };

    return (
        <>
            <Typography sx={{ pb: 1 }} variant="h2">Holiday Allowance</Typography>
            <Paper>
                <Grid container justifyContent="space-evenly" spacing={2}>
                    <Grid item container alignItems="center" justifyContent="space-evenly">
                        {loading &&
                            <CircularProgress size={120} thickness={3} />
                        }
                        {!loading &&
                            <>
                                <Grid item>
                                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                        <CircularProgress
                                            sx={{ transform: 'rotate(0deg)' }}
                                            size={120}
                                            thickness={3}
                                            variant="determinate"
                                            value={(holidayAllowance.holidayRemianingInDays / holidayAllowance.adjustedEntitlement) * 100}
                                        />
                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="h1"
                                                component="div"
                                            >{`${holidayAllowance.holidayRemianingInDays}`}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h2">Days remaining</Typography>
                                    <Typography>(of {holidayAllowance.adjustedEntitlement} Days)</Typography>
                                </Grid>
                            </>
                        }
                    </Grid>
                    <Grid item container
                        xs={12}
                        direction="column"
                        justifyContent="center"
                        alignItems="center">
                        {!loading &&
                            <Grid item>
                                <Typography variant='body1'>Your allowance resets on {new Date(holidayAllowance.holidayEndDate).toLocaleDateString('en-GB', { day: "2-digit", month: "2-digit", year: "numeric" })}</Typography>
                            </Grid>
                        }
                        <Grid item>
                            <Button onClick={() => setOpen(true)}>Click here to see how we calculate this</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <CalculationExplainerDialog open={open} onClose={handleDialogClose} />
            </Paper>
        </>
    );
}