import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AdminUserViewmodel from './Viewmodels/AdminUserViewmodel';
import { useNavigate } from 'react-router';

interface IProps {
    row: AdminUserViewmodel;
}

export default function UserManagementTableRow(props: IProps) {
    const navigate = useNavigate();
    const { row } = props;

    const profile = () => {
        navigate(
            '/Profile',
            {
                state: {
                    userId: row.id
                }
            }
        );
    }

    return (
        <TableRow onClick={profile} hover>
            <TableCell>{row.firstName}</TableCell>
            <TableCell>{row.lastName}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.role}</TableCell>
        </TableRow>
    );
}