import * as React from 'react';
import Navmenu from './Navmenu';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

interface IProps {
    children: any;
}

export default function Layout(props: IProps) {

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Navmenu />
            <Box component="main" sx={{ flexGrow: 1, p: 3, width: 'calc(100% - 240px)' }}>
                <Toolbar />
                {props.children}                
            </Box>
        </Box>
    );
}
