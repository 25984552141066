import * as React from 'react';
import Grid from '@mui/material/Grid';
import authService from '../../../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../../../Contexts/TokenRefreshContext';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import StaffStatsViewModel from '../Viewmodels/StaffStatsViewmodel';
import Typography from '@mui/material/Typography';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function StaffChart() {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [loading, setLoading] = React.useState(false);
    const [chartData, setChartData] = React.useState({
        labels: ['Active', 'Archived', 'Invited'],
        datasets: [{
            label: 'Users',
            data: [0, 0, 0],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)',
                'rgb(255, 205, 86)',
            ],
            hoverOffset: 4,
        }],
    });

    const options = {
        responsive: false,
        maintainAspectRatio: false,
    };

    React.useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(
            `Staff/GetStaffStats`,
            {
                headers: !token
                    ? { 'Content-Type': 'application/json; charset=utf-8' }
                    : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            },
            ResponseType.JSON,
            (data: StaffStatsViewModel) => {
                setChartData({
                    labels: ['Active', 'Archived', 'Invited'],
                    datasets: [{
                        label: 'Users',
                        data: [data.active, data.archived, data.invited],
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                        ],
                        hoverOffset: 4,
                    }],
                });

                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    };

    return (
        <>
            {!loading &&
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography>Staff Status</Typography>
                        <Doughnut data={chartData} options={options} />
                    </Grid>
                </Grid>
            }
        </>
    );
}
