import dayjs, { Dayjs } from "dayjs";

export default class UserEventViewModel {
    public id: number | null = null;
    public userId: string = '';
    public eventType: number | null = null;
    public note: string | null = null;
    public start: Dayjs = dayjs();
    public end: Dayjs = dayjs();
    public startDayType: number | null = null;
    public endDayType: number | null = null;
    public archived: boolean = false;
}