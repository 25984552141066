import * as React from 'react';
import ShiftHistoryViewModel from './Viewmodels/ShiftHistoryViewModel';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import ResponsiveTable from '../Utilities/ResponsiveTable';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ShiftHistoryTableRow from './ShiftHistoryTableRow';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import authService from '../api-authorization/AuthorizeService';
import { ResponseType, TokenRefreshContext } from '../Contexts/TokenRefreshContext';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableHead from '@mui/material/TableHead';

interface IProps {
    userId: string;
    onClose: (refresh: boolean) => void;
    open: boolean;
}

export default function ShiftHistoryTable({ userId, open, onClose }: IProps) {
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const [results, setResults] = React.useState<ShiftHistoryViewModel[]>([]);
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if(userId && userId.length > 0)
            getData();
    }, [userId]);

    const getData = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();

        await crabFetch(`Shift/GetShiftHistory?userId=${userId}`, {
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.JSON,
            (data: any) => {
                setResults(data.rows);
                setCount(data.count);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            fullWidth
            maxWidth={'md'}
        >
            <DialogTitle>
                <Grid container>
                    <Grid item>Shift History</Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <TableContainer>
                            <ResponsiveTable
                                aria-labelledby="tableTitle"
                                size={'medium'}
                                aria-label="shift history"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Shift Name</TableCell>
                                        <TableCell>Start Date</TableCell>
                                        <TableCell>End Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(!loading && results) && results.map((row: ShiftHistoryViewModel) => {
                                        return (<ShiftHistoryTableRow key={row.id} row={row} />);
                                    })
                                    }
                                    {count <= 0 && !loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * 5,
                                            }}
                                        >
                                            <TableCell colSpan={3} align="center">No Results Found</TableCell>
                                        </TableRow>
                                    }
                                    {loading &&
                                        <TableRow
                                            sx={{
                                                height: 53 * 5,
                                            }}
                                        >
                                            <TableCell colSpan={3} align="center"><CircularProgress color="primary" /></TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </ResponsiveTable>
                        </TableContainer>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button variant="outlined" onClick={() => onClose(false)}>Close</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}