import * as React from "react";
import Box from '@mui/material/Box';

interface IProps {
    colour: string;
    backgroundColour?: string | undefined;
    children: any;
}

export default function ColouredRow(props: IProps) {
    const { colour, backgroundColour, children } = props;

    return (
        <Box sx={{
            borderLeft: `10px solid ${colour}`,
            backgroundColor: `${backgroundColour ? backgroundColour : 'background.default'}`,
            p: 2,
            borderRadius: '10px'
        }}>
            {children}
        </Box>
    );
}