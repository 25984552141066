import * as React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import StaffTableViewModel from './Viewmodels/StaffTableViewModel';
import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import authService from '../api-authorization/AuthorizeService';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import { AlertContext } from '../Contexts/AlertContext';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import WarningDialog from '../Utilities/WarningDialog';

interface IProps {
    row: StaffTableViewModel;
    refresh: () => void;
}

export default function StaffTableRow(props: IProps) {
    const { row, refresh } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(false);
    const [archiving, setArchiving] = React.useState(false);

    React.useEffect(() => {

    }, []);

    const openArchiveWarning = () => {
        setArchiving(true);

    }

    const toggleArchive = async () => {
        const token = await authService.getAccessToken();
        setLoading(true);

        const method = row.status ? 'UnArchiveUser' : 'ArchiveUser';

        crabFetch(`User/${method}?id=${row.id}`, {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    show('success', `Successfully ${row.status ? 're-enabled' : 'archived'} User`);
                    refresh();
                }
                setLoading(false);
            },
            (error: any) => {
                setLoading(false);
                show('error', error);
            }
        );
    }

    return (
        <>
            <TableRow hover>
                <TableCell>
                    <CardHeader
                        avatar={
                            <Avatar {...stringAvatar(row.name)} />
                        }
                        title={row.name}
                        subheader={row.role}
                        sx={{ m: -2 }}
                    />
                </TableCell>
                <TableCell>{row.memberOf}</TableCell>
                <TableCell>{!row.status! ? "Active" : "Removed"}</TableCell>
                <TableCell align='right'>
                    <Grid container spacing={1} justifyContent='flex-end' >
                        <Grid>
                            <LoadingButton size='small' loading={loading} disabled={loading || row.status} variant={'contained'} onClick={openArchiveWarning}>Remove</LoadingButton>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
            <WarningDialog open={archiving} close={() => setArchiving(false)} buttonText='Remove' text='This staff member will no longer be able to log into teamplanner and you will not be able to reactivate them.' okClick={toggleArchive} />
        </>
    );
}

function stringAvatar(name: string) {
    var words = name.split(' ').filter((item: string) => item !== ' ');
    var initals = words[0][0].toUpperCase() + words[1][0].toUpperCase();

    return {
        sx: {
            bgcolor: '#BDBDBD',
        },
        children: initals,
    };
}