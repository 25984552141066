export default class EventRequestTableViewModel {
    public id: number = 0;
    public requestedById: string = '';
    public name: string = '';
    public teamId: number = 0;
    public team: string = '';
    public status: string = '';
    public colour: string = '';
    public icon: string = '';
    public from: Date = new Date();
    public to: Date = new Date();
    public note: string = '';
    public rejectReason?: string = '';
    public type?: string = '';
    public fullStart?: boolean = true;
    public fullEnd?: boolean = true;
    public affectsEntitlement?: number = 0;
}