import * as React from 'react';
import Layout from './components/Layout';
import Dashboard from './components/Dashboard';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import AlertProvider from './components/Contexts/AlertContext';
import { SnackbarProvider } from 'notistack';
import { Route, Routes } from 'react-router-dom';
import { ApplicationPaths, LoginActions, LogoutActions } from './components/api-authorization/ApiAuthorizationConstants';
import Login from './components/api-authorization/Login';
import Logout from './components/api-authorization/Logout';
import { ThemeProvider, responsiveFontSizes, createTheme, darken } from '@mui/material/styles';
import TokenRefreshProvider from './components/Contexts/TokenRefreshContext';
import TermsCondsProvider from './components/Contexts/TermsCondsContext';
import AdminDashboard from './components/Admin/Dashboard/AdminDashboard';
import RegisterConfirmation from './components/Profile/RegisterConfirmation';
import AdminProvider from './components/Contexts/AdminContext';
import CompanySettings from './components/Companies/CompanySettings';
import StaffTable from './components/Staff/StaffTable';
import ManageStaffMember from './components/Staff/ManageStaffMember';
import ShiftsTable from './components/Shifts/ShiftsTable';
import ManageHolidays from './components/Companies/ManageCompanyEvents';
import TeamTable from './components/Teams/TeamTable';
import EventTable from './components/Event/EventTypeTable';
import OnboardingProvider from './components/Admin/Dashboard/Onboarding';
import YourCalendar from './components/Dashboard/YourCalendar';
import UserManagementTable from './components/Admin/Dashboard/UserManagementTable';
import BillingProvider from './components/Contexts/BillingContext';
import AdminStats from './components/Admin/Dashboard/AdminStats';

let theme = createTheme({
    shape: {
        borderRadius: 10
    },
    typography: {
        fontFamily: 'Lexend Deca',
        h1: {
            fontWeight: 600,
            fontSize: 20
        },
        h2: {
            fontWeight: 500,
            fontSize: 18
        },
        h3: {
            fontWeight: 400,
            fontSize: 16
        },
        h4: {
            fontWeight: 400,
            fontSize: 14
        },
        h5: {
            fontWeight: 400,
            fontSize: 14,
            color: '#456FEC'
        },
        h6: {
            fontWeight: 300,
            fontSize: 14
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: 14
        },
        subtitle2: {
            fontWeight: 400,
            fontSize: 14
        },
        body1: {
            fontWeight: 400,
            fontSize: 14
        },
        body2: {
            fontWeight: 300,
            fontSize: 14
        },
        caption: {
            fontWeight: 300,
            fontSize: 13,
            color: '#B5ADC4'
        },
        overline: {
            fontWeight: 300,
            fontSize: 14
        },
        button: {
            fontWeight: 400,
            fontSize: 14,
            textTransform: 'none'
        }
    },
    palette: {
        background: {
            default: '#F2F4F8',
            paper: '#fff'
        },
        text: {
            primary: '#1A263E',
            secondary: '#B5ADC4'
        },
        primary: {
            main: '#456FEC',
            contrastText: 'white'
        },
        secondary: {
            main: '#1A263E',
            contrastText: 'white'
        },
        error: {
            main: '#F65647'
        }
    },
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    border: '1px solid #F5F6F8',
                    padding: '16px',
                    boxShadow: '6px 4px 20px rgba(66, 111, 205, 0.15)'
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    border: 'none'
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    boxShadow: 'inset 6px 4px 20px #426FCD26'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    //borderColor: '#707683 !important',
                    //borderWidth: 0,
                    '&:focus': {
                        borderColor: '#B5ADC4',
                        '--Input-focused': 1,
                    },
                    "&.Mui-focused": {
                        //border: '2px solid #B5ADC4',
                        "&.MuiOutlinedInput-notchedOutline": {
                            //border: '2px solid #B5ADC4'
                        }
                    }
                },
                root: {
                    backgroundColor: '#FBFCFE',
                    //border: '1px solid',
                    borderColor: "#cdd7e1",
                    boxSizing: 'border-box',
                    boxShadow: '0 0 #000, 0px 1px 2px 0px rgba(21, 21, 21, 0.08)',
                    '--Input-focused': 0,
                    '&:before': {
                        boxSizing: 'border-box',
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        pointerEvents: 'none',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                        margin: 'calc(1px * -1)',
                        boxShadow: 'inset 0 0 0 calc(var(--Input-focused) * 2px) #456FEC',
                        borderRadius: 'inherit',
                    },
                    '&.Mui-focused fieldset': {
                        '--Input-focused': 1,
                        //borderColor: "#B5ADC4 !important",
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#1A263E',
                    webkitTransform: 'translate(0, -1.5px) scale(0.9)',
                    transform: 'translate(0, -1.5px) scale(0.9)'
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    borderRadius: '10px 10px 0 0'
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    //border: '1px solid #707683 !important',
                    //borderTop: 'none !important',
                    //boxShadow: 'none !important',
                    borderRadius: '10px',
                    padding: '0px !important'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'contained' &&
                        (ownerState.color === 'primary' ? {
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.main,
                                boxShadow: 'none'
                            }
                        } : {
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                boxShadow: 'none'
                            }
                        })
                    ),
                    boxShadow: 'none',
                    textTransform: 'none',
                }),
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    '&:nth-of-type(odd)': {
                        backgroundColor: '#F2F4F8',
                        '&td': {
                            '&:first-of-type': {
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5
                            },
                            '&:last-child': {
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5
                            },
                        }
                    },
                },
                hover: {
                    backgroundColor: darken('#F2F4F8', 0.25),
                    color: 'red',
                    '&:nth-of-type(odd)': {
                        backgroundColor: darken('#F2F4F8', 0.25),
                        '&:first-of-type': {
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5
                        },
                    }
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& tr': {
                        backgroundColor: '#fff!important'
                    }
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...theme.typography.h5,
                    "&.Mui-active": {
                        color: '#456FEC !important'
                    }
                }),
                icon: {
                    color: '#456FEC !important'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                body: {
                    border: 'none'
                },
                head: {
                    borderColor: '#B5ADC4'

                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: 0
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...theme.typography.h1,
                    borderBottom: "1px solid #B5ADC4"
                })
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: '16px!important'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    borderTop: '1px solid #B5ADC4',
                    margin: '16px -24px 0',
                    padding: '16px 24px 0'
                }
            }
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1A263EB2',
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderBottomWidth: 2,
                    borderColor: theme.palette.text.secondary

                })
            }
        }
    }
});

theme = responsiveFontSizes(theme);

function loginAction(name: any) {
    return (<Login action={name}></Login>);
}

function logoutAction(name: any) {
    return (<Logout action={name}></Logout>);
}

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <AlertProvider>
                    <TokenRefreshProvider>
                        <AdminProvider>
                            <TermsCondsProvider>
                                <BillingProvider>
                                    <OnboardingProvider>
                                        <Layout>
                                            <Routes>
                                                <Route path='/' element={<AuthorizeRoute component={Dashboard} />} />
                                                <Route path='/AdminStats' element={<AuthorizeRoute component={AdminStats} />} />
                                                <Route path='/Calendar' element={<AuthorizeRoute component={YourCalendar} />} />
                                                <Route path='/AdminManagement' element={<AuthorizeRoute component={AdminDashboard} />} />
                                                <Route path='/UserManagement' element={<AuthorizeRoute component={UserManagementTable} />} />
                                                <Route path='/CompanySettings' element={<AuthorizeRoute component={CompanySettings} />} />
                                                <Route path='/Staff' element={<AuthorizeRoute component={StaffTable} />} />
                                                <Route path='/Staff/Management' element={<AuthorizeRoute component={ManageStaffMember} />} />
                                                <Route path='/Teams' element={<AuthorizeRoute component={TeamTable} />} />
                                                <Route path='/Shifts' element={<AuthorizeRoute component={ShiftsTable} />} />
                                                <Route path='/ManageHolidays' element={<AuthorizeRoute component={ManageHolidays} />} />
                                                <Route path='/RegisterConfirmation/:userId/:code' element={<RegisterConfirmation />} />
                                                <Route path='/EventTypes' element={<AuthorizeRoute component={EventTable} />} />

                                                <Route path={ApplicationPaths.ApiAuthorizationPrefix}>
                                                    <Route path={ApplicationPaths.Login} element={loginAction(LoginActions.Login)} />
                                                    <Route path={ApplicationPaths.LoginFailed} element={loginAction(LoginActions.LoginFailed)} />
                                                    <Route path={ApplicationPaths.LoginCallback} element={loginAction(LoginActions.LoginCallback)} />
                                                    <Route path={ApplicationPaths.Profile} element={loginAction(LoginActions.Profile)} />
                                                    <Route path={ApplicationPaths.Register} element={loginAction(LoginActions.Register)} />
                                                    <Route path={ApplicationPaths.LogOut} element={logoutAction(LogoutActions.Logout)} />
                                                    <Route path={ApplicationPaths.LogOutCallback} element={logoutAction(LogoutActions.LogoutCallback)} />
                                                    <Route path={ApplicationPaths.LoggedOut} element={logoutAction(LogoutActions.LoggedOut)} />
                                                </Route>
                                            </Routes>
                                        </Layout>
                                    </OnboardingProvider>
                                </BillingProvider>
                            </TermsCondsProvider>
                        </AdminProvider>
                    </TokenRefreshProvider>
                </AlertProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}
