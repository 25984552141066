

export default class CompanyEventViewModel {
    public change: number = 0;
    public reason: string = "";
    public eventType: number = 0;
    public start: Date = new Date();
    public partialStartDay: string[] = [];
    public type: string = '';
    public hasSelectedAll: boolean = false;
    public teamFilter: number = 0;
 
}