import * as React from 'react';
import { DatePickerElement, FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { TokenRefreshContext, ResponseType } from '../Contexts/TokenRefreshContext';
import authService from '../api-authorization/AuthorizeService';
import { AlertContext } from '../Contexts/AlertContext';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/en-gb';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SendIcon from '@mui/icons-material/Send';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface IProps {
    AddRow: () => void;
    entitlement: number;
    startDate: Dayjs | null;
}

export default function OboardRow(props: IProps) {
    const { AddRow, entitlement, startDate } = props;
    const { crabFetch } = React.useContext(TokenRefreshContext);
    const { show } = React.useContext(AlertContext);
    const [submitted, setSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const formContext = useForm({
        defaultValues: { firstName: '', lastName: '', email: '', entitlement: entitlement, start: startDate, }
    });

    const submit = async (form: any) => {
        setLoading(true);
        const token = await authService.getAccessToken();

        if (form.start) {
            const offset = form.start.utcOffset();
            form.startDate = form.start.add(offset, 'm');
        }

        crabFetch('Staff/AddOnboardStaff', {
            method: 'POST',
            headers: !token ? { 'Content-Type': 'application/json; charset=utf-8' } : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(form)
        }, ResponseType.Text,
            (data: any) => {
                if (data.length > 0) show('error', data);
                else {
                    setSubmitted(true);
                    AddRow();
                }
                setLoading(false);
            },
            (error: any) => {
                show('error', error);
                setLoading(false);
            }
        );
    }

    return (
        <Grid container>
            <FormContainer formContext={formContext} onSuccess={submit}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                    <Grid container item spacing={2} sx={{ pb: 1 }}>
                        <Grid item xs={2}>
                            <TextFieldElement autoComplete='off' disabled={submitted} name="firstName" required fullWidth size="small" validation={{validate: value => value.trim() !== "" || "Field cannot be empty"}} />
                        </Grid>
                        <Grid item xs={2}>
                            <TextFieldElement autoComplete='off' disabled={submitted} name="lastName" required fullWidth size="small" validation={{ validate: value => value.trim() !== "" || "Field cannot be empty" }} />
                        </Grid>
                        <Grid item xs={2.4}>
                            <TextFieldElement autoComplete='off' disabled={submitted} name="email" required fullWidth size="small" validation={{ validate: value => value.trim() !== "" || "Field cannot be empty" }} />
                        </Grid>
                        <Grid item xs={2.5}>
                            <TextFieldElement autoComplete='off' InputProps={{
                                endAdornment: <InputAdornment position="end">days</InputAdornment>,
                            }} disabled={submitted} name="entitlement" required fullWidth size="small" />
                        </Grid>
                        <Grid item xs={2}>
                            <DatePickerElement disabled={submitted} name="start" required inputProps={{ fullWidth: true, size: "small" }} />
                        </Grid>
                        <Grid item xs={1.1}>
                            <LoadingButton loading={loading} disabled={submitted || loading} variant="contained" type="submit" endIcon={!submitted ? <SendIcon /> : <CheckCircleOutlineIcon />}>{!submitted ? "Invite" : "Invited"}</LoadingButton>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </FormContainer>
        </Grid>
    );
}