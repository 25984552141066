import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import EventRequestTableViewModel from '../Event/Viewmodels/EventRequestTableViewModel';
import PendingRequestsRow from './YourCalRequestRow';
import { FormContainer, SelectElement, useForm } from 'react-hook-form-mui';
import ViewEventRequestDialog from '../Event/ViewEventRequestDialog';
import CircularProgress from '@mui/material/CircularProgress';

interface IProps {
    getStaff: (monthFilter: any) => void;
    requests: any;
    getRequests: (dropdown: string) => void;
    loading: boolean;
}

export default function PendingRequests(props: IProps) {
    const { getStaff, requests, getRequests, loading } = props;
    const [statusDropDown, setDropdown] = React.useState('0');
    const [view, setView] = React.useState<EventRequestTableViewModel | null>(null);

    const closeDialog = (refresh: boolean) => {
        setView(null);

        if (refresh) {
            getStaff(new Date());
            getRequests(statusDropDown);
        }
    }

    const formContext = useForm({
        defaultValues: { dropDown: 0 }
    });

    React.useEffect(() => {
        getRequests(statusDropDown);
    }, []);

    React.useEffect(() => {
        getRequests(statusDropDown);
    }, [statusDropDown]);

    const handleSelect = (event: any) => {
        setDropdown(event);
    }

    return (
        <Paper>
            <Grid container spacing={2}>
                <Grid item>
                    <FormContainer formContext={formContext}>
                        <SelectElement size={'small'} onChange={handleSelect} name={'dropDown'} options={[
                            {
                                id: '0',
                                label: 'Pending'
                            },
                            {
                                id: '1',
                                label: 'Rejected'
                            },
                            {
                                id: '2',
                                label: 'Approved'
                            }
                        ]}></SelectElement>
                    </FormContainer>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    {(!loading && requests) && requests.map((row: EventRequestTableViewModel) => {
                        return (<PendingRequestsRow key={row.id} row={row} viewRequest={setView} />);
                    })
                    }
                    {loading &&
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    }
                    {!loading && requests.length <= 0 &&
                        <Grid item>
                            <Typography>No Requests</Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {view &&
                <ViewEventRequestDialog request={view!} open={view !== null} onClose={closeDialog} />
            }
        </Paper>
    );
}